const initialState = {
    sales: [],
    allSales: [],
    detail: [],
    userLogued: [],
    errorLogued: null,
    userSales: [],
    allUsers: [],
    salechange: [],
    filteredSales: [],
    filters: {},
    totalSales: {}
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_SALES':
            return {
                ...state,
                sales: action.payload,
                allSales: action.payload
            }

        case 'DELETE_ALL_SALES':
            return {
                ...state,
                sales: []
            }

        case 'GET_DETAIL':
            return {
                ...state,
                detail: action.payload
            }
        
        case 'DELETE_DETAIL': 
            return {
                ...state,
                detail: []
            }

        case 'GET_ALL_USERS':
            return {
                ...state,
                allUsers: action.payload
            }

        case 'USER_SALES': 
            return {
                ...state,
                userSales: action.payload
            }

        case 'GET_SALECHANGE': 
            return {
                ...state,
                salechange: action.payload
            }

        case 'DELETE_SALECHANGE':
            return {
                ...state,
                salechange: []
            }

        case "FILTER_ALL_SALES":
            return {
                ...state,
                sales: action.payload,
                filteredSales: action.payload
            }

        case 'DELETE_FILTER_ALL_SALES':
            return {
                ...state,
                filters: {}
            }

        case 'SAVE_FILTERS': 
            return {
                ...state,
                filters: action.payload
            }

        case 'GET_TOTAL_SALES':
            return {
                ...state,
                totalSales: action.payload
            }

        case 'LOGIN_SUCCESS':
            return {
                ...state,
                userLogued: action.payload
            }

        case 'LOGIN_FAILURE':
            return {
                ...state,
                errorLogued: action.payload
            }

        default:
            return {
                state
            }
    }
}

export default rootReducer