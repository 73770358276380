import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import estilos from './styles/SearchBar.module.css'
import botones from './styles/Botones.module.css'
import axios from "axios";

export default function Login() {
  const navigate = useNavigate();

  const [input, setInput] = useState({
    email: "",
    password: ""
  });

  useEffect(() => {
    const userInfo = sessionStorage.getItem('userRol');
    if (userInfo === 'ADMINISTRADOR' || userInfo === 'VENDEDOR') {
      navigate(userInfo === 'ADMINISTRADOR' ? '/admin/home' : '/vendedor/home');
    } else if (userInfo === 'SUPERVISOR') {
      navigate('/supervisor/home');
    } else if (userInfo === '4PLAY') {
      navigate('/4play/home')
    } else if(userInfo === 'SUPER ADMIN') {
      navigate('/super admin/home')
    }
  }, [navigate]);

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value
    });
  }

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      const responseLogin = await axios.post('https://back-crm-app.vercel.app/user/login', input)
      sessionStorage.setItem('token', responseLogin.data.accessToken)
      sessionStorage.setItem('userId', responseLogin.data.user?.id)
      sessionStorage.setItem("userName", responseLogin.data.user?.name)
      sessionStorage.setItem("userRol", responseLogin.data.user?.rol)
      sessionStorage.setItem('userEmail', responseLogin.data.user?.email)

      const userInfo = sessionStorage.getItem('userRol');
      if (userInfo === 'ADMINISTRADOR' || userInfo === 'VENDEDOR') {
        navigate(userInfo === 'ADMINISTRADOR' ? '/admin/home' : '/vendedor/home');
      } else if (userInfo === 'SUPERVISOR') {
        navigate('/supervisor/home');
      } else if (userInfo === '4PLAY') {
        navigate('/4play/home')
      } else if(userInfo === 'SUPER ADMIN') {
        navigate('/super admin/home')
      }

      return alert('Inicio de sesión exitoso')
    } catch (error) {
      return alert(error.response?.data?.msg)
    }
  }

  return (
    <div>
      <h3>Inicio de Sesión</h3>
      <form onSubmit={handleSubmit}>
        <br />
        <input className={estilos.input} required type="email" name="email" placeholder="INGRESE SU CORREO" value={input.email} onChange={handleChange} />
        <br />
        <br />
        <input className={estilos.input} required type="password" name="password" placeholder="INGRESE SU CONTRASEÑA" value={input.password} onChange={handleChange} />
        <br />
        <br />
        <button className={botones.boton_5} type="submit">Iniciar sesión</button>
      </form>
    </div>
  );
}