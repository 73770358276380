import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllSales, saveFilters, getAllUsers, deleteFilterAllSales, verifyRol } from '../actions/index';
import { NavLink, useNavigate } from "react-router-dom";
import { statusSale, products, provincias, supervisor } from './data'
import Paginated from './Paginated'
import moment from 'moment'
import editIcon from './assets/icono editar.png'
import registerIcon from './assets/icono registro.png'
import estilos from './styles/Table_Sales.module.css'
import botones from './styles/Botones.module.css'
import textos from './styles/Textos.module.css'
import sb from './styles/SearchBar.module.css'
// import { utils, write } from 'xlsx';

export default function Sales() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userRol = sessionStorage.getItem('userRol')

    const allUsers = useSelector((state) => state.allUsers)
    const sales = useSelector((state) => state.sales)
    const filters = useSelector((state) => state.filters)
    const totalSales = useSelector((state) => state.totalSales)

    const page = parseInt(sessionStorage.getItem('currentPage')) || 1;

    const [input, setInput] = useState(filters)

    // const downloadExcel = () => {
    //     const workbook = utils.book_new();

    //     const worksheet = utils.json_to_sheet(allSales);

    //     utils.book_append_sheet(workbook, worksheet, 'Ventas');

    //     const excelData = write(workbook, { bookType: 'xlsx', type: 'array' });

    //     const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    //     const excelURL = URL.createObjectURL(blob);

    //     const tempLink = document.createElement('a');
    //     tempLink.href = excelURL;
    //     tempLink.setAttribute('download', 'ventas.xlsx');
    //     tempLink.click();

    //     URL.revokeObjectURL(excelURL);
    // };


    // FILTROS //

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        sessionStorage.setItem('currentPage', 1)
        dispatch(getAllSales({ page: page, ...input }))
        dispatch(saveFilters(input))
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit(e)
        }
    }

    const cleanFilters = (e) => {
        e.preventDefault()
        const resetPage = sessionStorage.setItem('currentPage', 1)
        dispatch(getAllSales({ page: resetPage }))
        dispatch(deleteFilterAllSales())
    }

    // USE EFFECT //

    useEffect(() => {
        const checkUserRol = async () => {
            try {
                // Realiza la verificación del rol
                await dispatch(verifyRol());

                // Obtiene el rol del sessionStorage después de la verificación
                const userRol = sessionStorage.getItem('userRol');

                // Realiza la lógica de redirección basada en el rol
                if (userRol === 'INACTIVO') {
                    sessionStorage.clear();
                    navigate('/');
                }
            } catch (error) {
                console.error('Error during user role verification:', error);
            }
        };

        checkUserRol();
    }, [dispatch, navigate]);

    useEffect(() => {
        dispatch(getAllUsers())
        dispatch(getAllSales({ page: page, ...filters }))
    }, [dispatch, page, filters])

    return (
        <div>
            {
                userRol === 'ADMINISTRADOR' || userRol === 'SUPER ADMIN' ?
                    <div>
                        <h1>Solicitudes</h1>
                        <div onKeyDown={handleKeyDown}>
                            <div className={textos.div_text}>
                                <p><b>Fecha de venta</b></p>
                                <p><b>Fecha de aprobación</b></p>
                                <p><b>Fecha de instalación</b></p>
                            </div>
                            <input className={sb.input} type="date" name="saleDateStart" defaultValue={input?.saleDateStart} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="date" name="saleDateEnd" defaultValue={input?.saleDateEnd} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="date" name="approvalDateStart" defaultValue={input?.approvalDateStart} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="date" name="approvalDateEnd" defaultValue={input?.approvalDateEnd} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="date" name="installationDateStart" defaultValue={input?.installationDateStart} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="date" name="installationDateEnd" defaultValue={input?.installationDateEnd} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="text" name="name" placeholder="NOMBRE" defaultValue={input?.name} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="text" name="dni" placeholder="DNI" defaultValue={input?.dni} onChange={(e) => handleChange(e)} />
                            <select className={sb.input} name="province" defaultValue={input?.province} onChange={(e) => handleChange(e)}>
                                <option value="" selected>PROVINCIA</option>
                                {
                                    provincias.map(p => {
                                        return (
                                            <option value={p.name} key={p.id}>{p.name}</option>
                                        )
                                    })
                                }
                            </select>                            <input className={sb.input} type="text" name="location" placeholder="LOCALIDAD" defaultValue={input?.location} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="text" name="aditionalPhone" placeholder="NUMERO ADICIONAL #1" defaultValue={input?.aditionalPhone} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="text" name="aditionalPhone2" placeholder="NUMERO ADICIONAL #2" defaultValue={input?.aditionalPhone2} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="text" name="totalizeNumber" placeholder="LINEA A TOTALIZAR" value={input?.totalizeNumber} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="text" name="order" placeholder="ORDEN" defaultValue={input?.order} onChange={(e) => handleChange(e)} />
                            <select className={sb.input} name="statusSale" defaultValue={input?.statusSale} onChange={(e) => handleChange(e)}>
                                <option value="" selected>ESTADO DE SOLICITUD</option>
                                {
                                    statusSale.map(s => {
                                        return (
                                            <option value={s.name} key={s.id}>{s.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <select className={sb.input} name="userT3" defaultValue={input?.userT3} onChange={(e) => handleChange(e)} >
                                <option value="" selected>USUARIO T3</option>
                                <option value="VSIERRA">VSIERRA</option>
                                <option value="FLBARRIO">FLBARRIO</option>
                                <option value="4PLAY">4PLAY</option>
                            </select>
                            <input className={sb.input} type="text" name="central" placeholder="CENTRAL" defaultValue={input?.central} onChange={(e) => handleChange(e)} />
                            <input className={sb.input} type="text" name="numberApple" placeholder="MANZANA" defaultValue={input?.numberApple} onChange={(e) => handleChange(e)} />
                            <select className={sb.input} name="previousProduct" defaultValue={input?.previousProduct} onChange={(e) => handleChange(e)}>
                                <option value="" selected>PRODUCTO PREVIO</option>
                                <option value="ALTA MIXTA FIBRA">ALTA MIXTA FIBRA</option>
                                <option value="ALTA PURA FIBRA">ALTA PURA FIBRA</option>
                                <option value="ALTA MIGRA FIBRA">ALTA MIGRA FIBRA</option>
                                <option value="LINEA NUEVA">LINEA NUEVA</option>
                                <option value="PORTA">PORTA</option>
                            </select>
                            <select className={sb.input} name="product" defaultValue={input?.product} onChange={(e) => handleChange(e)} >
                                <option value="" selected>PRODUCTO</option>
                                {
                                    products.map(s => {
                                        return (
                                            <option value={s.name} key={s.id}>{s.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <select className={sb.input} name="supervisor" defaultValue={input?.supervisor} onChange={(e) => handleChange(e)} >
                                <option value="" selected>SUPERVISOR</option>
                                {
                                    supervisor.map(s => {
                                        return (
                                            <option value={s.name} key={s.id}>{s.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <select className={sb.input} name="seller" defaultValue={input?.seller} onChange={(e) => handleChange(e)}>
                                <option value="" selected>VENDEDOR</option>
                                {
                                    allUsers?.map(u => {
                                        return (
                                            <option value={u.name} key={u.id}>{u.name}</option>
                                        )
                                    }
                                    )}
                            </select>
                            <br />
                            <button className={botones.boton_5} type="Submit" onClick={(e) => handleSubmit(e)}>Filtrar</button>

                        </div>
                        <div className={botones.div_botones}>
                            {
                                userRol === 'ADMINISTRADOR' ?
                                    <NavLink to='/admin/home'><button className={botones.boton_7}>Volver</button></NavLink>
                                    :
                                    <NavLink to='/super admin/home'><button className={botones.boton_7}>Volver</button></NavLink>
                            }
                            {/* <button className={botones.boton_7} onClick={downloadExcel}>Descargar Excel</button> */}
                            <button className={botones.boton_7} onClick={(e) => cleanFilters(e)}>Actualizar</button>
                        </div>
                        {
                            // sales?.length === 0 ? (
                            //     <p>Cargando...</p>
                            // ) : (
                            sales?.map(s => {
                                return (
                                    <div key={s.id} className={estilos.div_general} style={s.statusSale === "FTTH POSTE EN N" ? { border: "1px solid #00aa9e" } : s.statusSale === "FTTH CON TK A RESOLVER" ? { border: "1px solid #004e79" } : s.statusSale === "FTTH PARA ACTIVAR" ? { border: "1px solid #c005ff" } : s.statusSale === "FTTH PREVENTA" || s.statusSale === "PREVENTA" ? { border: "1px solid #337ab7" } : s.statusSale === "FTTH RECLAMADA" ? { border: "1px solid #797979" } : s.statusSale === "FTTH RECHAZADA" || s.statusSale === "RECHAZADA" ? { border: "1px solid #dc1e00" } : s.statusSale === "FTTH INSTALADA CON ÉXITO" || s.statusSale === "APROBADA" ? { border: "1px solid #0d7900" } : s.statusSale === "FTTH ACTIVADA EN ESPERA DE INSTALACIÓN" ? { border: "1px solid #c89700" } : s.statusSale === "FTTH CANCELADA" || s.statusSale === "CANCELADA" ? { border: "1px solid black" } : s.statusSale === "FTTH SUSPENDIDA" ? { border: "1px solid #FA721E " } : s.statusSale === "FTTH CALIDAD" ? { border: "1px solid salmon" } : s.statusSale === "FTTH ACTIVADA EN N" ? { border: "1px solid #B8CC11" } : s.statusSale === "FTTH ENVIADA 4PLAY" ? { border: "1px solid #804000" } : s.statusSale === 'FTTH NEGOCIO' ? { border: '1px solid #7B68EE' } : s.statusSale === 'PORTA CORREO MOVI' ? { border: '1px solid #FF69B4' } : s.statusSale === 'PORTA L PROPIA' ? { border: '1px solid #D7D528' } : s.statusSale === 'ENVIADA' ? { border: '1px solid #D72878' } : { border: "1px solid white" }}>
                                        <div className={estilos.div_detalles} style={s.statusSale === "FTTH POSTE EN N" ? { backgroundColor: "#00aa9e", color: "white" } : s.statusSale === "FTTH CON TK A RESOLVER" ? { backgroundColor: "#004e79", color: "white" } : s.statusSale === "FTTH PARA ACTIVAR" ? { backgroundColor: "#c005ff", color: "white" } : s.statusSale === "FTTH PREVENTA" || s.statusSale === 'PREVENTA' ? { backgroundColor: "#337ab7", color: "white" } : s.statusSale === "FTTH RECLAMADA" ? { backgroundColor: "#797979", color: "white" } : s.statusSale === "FTTH RECHAZADA" || s.statusSale === "RECHAZADA" ? { backgroundColor: "#dc1e00", color: "white" } : s.statusSale === "FTTH INSTALADA CON ÉXITO" || s.statusSale === "APROBADA" ? { backgroundColor: "#0d7900", color: "white" } : s.statusSale === "FTTH ACTIVADA EN ESPERA DE INSTALACIÓN" ? { backgroundColor: "#c89700", color: "white" } : s.statusSale === "FTTH CANCELADA" || s.statusSale === "CANCELADA" ? { backgroundColor: "black", color: "white" } : s.statusSale === "FTTH SUSPENDIDA" ? { backgroundColor: "#FA721E ", color: "white" } : s.statusSale === "FTTH CALIDAD" ? { backgroundColor: "salmon", color: "white" } : s.statusSale === "FTTH ACTIVADA EN N" ? { backgroundColor: "#B8CC11", color: "white" } : s.statusSale === "FTTH ENVIADA 4PLAY" ? { backgroundColor: "#804000", color: "white" } : s.statusSale === 'FTTH NEGOCIO' ? { backgroundColor: '#7B68EE', color: "white" } : s.statusSale === 'PORTA CORREO MOVI' ? { backgroundColor: '#FF69B4', color: 'white' } : s.statusSale === 'PORTA L PROPIA' ? { backgroundColor: "#D7D528", color: "white" } : s.statusSale === 'ENVIADA' ? { backgroundColor: "#D72878", color: "white" } : { backgroundColor: "white" }}>
                                            <p><b>Solicitud: </b>{s.order}</p>
                                            <p><b>Fecha cita: </b>{moment(s.installationDate).isValid() ? moment(s.installationDate).format('DD/MM/YYYY') : ""} {s.installationTime}</p>
                                            <p><b>Fecha de aprobación: </b>{moment(s.approvalDate).isValid() ? moment(s.approvalDate).format('DD/MM/YYYY') : ""}</p>
                                            <div>
                                                <NavLink to={'/admin/ventas/detalles/' + s.id}><img src={editIcon} alt="EDITAR" width="25px" height="25px" style={{ margin: "5px" }} /></NavLink>
                                                <NavLink to={'/admin/registro/' + s.id}><img src={registerIcon} alt="REGISTRO" width="25px" height="25px" style={{ margin: "5px" }} /></NavLink>
                                            </div>
                                        </div>
                                        <div className={estilos.div_comentarios} style={s.statusSale === "FTTH POSTE EN N" ? { backgroundColor: "#00aa9e", color: "white" } : s.statusSale === "FTTH CON TK A RESOLVER" ? { backgroundColor: "#004e79", color: "white" } : s.statusSale === "FTTH PARA ACTIVAR" ? { backgroundColor: "#c005ff", color: "white" } : s.statusSale === "FTTH PREVENTA" || s.statusSale === 'PREVENTA' ? { backgroundColor: "#337ab7", color: "white" } : s.statusSale === "FTTH RECLAMADA" ? { backgroundColor: "#797979", color: "white" } : s.statusSale === "FTTH RECHAZADA" || s.statusSale === "RECHAZADA" ? { backgroundColor: "#dc1e00", color: "white" } : s.statusSale === "FTTH INSTALADA CON ÉXITO" || s.statusSale === "APROBADA" ? { backgroundColor: "#0d7900", color: "white" } : s.statusSale === "FTTH ACTIVADA EN ESPERA DE INSTALACIÓN" ? { backgroundColor: "#c89700", color: "white" } : s.statusSale === "FTTH CANCELADA" || s.statusSale === "CANCELADA" ? { backgroundColor: "black", color: "white" } : s.statusSale === "FTTH SUSPENDIDA" ? { backgroundColor: "#FA721E ", color: "white" } : s.statusSale === "FTTH CALIDAD" ? { backgroundColor: "salmon", color: "white" } : s.statusSale === "FTTH ACTIVADA EN N" ? { backgroundColor: "#B8CC11", color: "white" } : s.statusSale === "FTTH ENVIADA 4PLAY" ? { backgroundColor: "#804000", color: "white" } : s.statusSale === 'FTTH NEGOCIO' ? { backgroundColor: '#7B68EE', color: "white" } : s.statusSale === 'PORTA CORREO MOVI' ? { backgroundColor: '#FF69B4', color: 'white' } : s.statusSale === 'PORTA L PROPIA' ? { backgroundColor: "#D7D528", color: "white" } : s.statusSale === 'ENVIADA' ? { backgroundColor: "#D72878", color: "white" } : { backgroundColor: "white" }}>
                                            <p className={estilos.p_comentarios}><b>Comentarios: </b>{s.comments}</p>
                                        </div>
                                        <div className={estilos.div_datos}>
                                            <div>
                                                <p><b>Cliente: </b>{s.name} {s.lastName}</p>
                                                <p><b>Dni: </b>{s.dni}</p>
                                                <p><b>Linea a portar(PORTA): </b>{s.portNumber}</p>
                                                <p><b>Compañia de teléfono(PORTA): </b>{s.phoneCompany}</p>
                                                <p><b>PIN(PORTA): </b>{s.pinNumber}</p>
                                                <p><b>Fecha de vto de PIN(PORTA): </b>{moment(s.pinVto).isValid() ? moment(s.pinVto).format('DD/MM/YYYY') : ""}</p>
                                            </div>
                                            <div>
                                                <p><b>Fecha de venta: </b>{moment(s.saleDate).format('DD/MM/YYYY')}</p>
                                                <p><b>Vendedor: </b>{s.users[0]?.name}</p>
                                                <p><b>Provincia: </b>{s.province}</p>
                                                <p><b>Localidad: </b>{s.location}</p>
                                                <p><b>Calle: </b>{s.street}</p>
                                                <p><b>Barrio: </b>{s.neighborhood}</p>
                                            </div>
                                            <div>
                                                <p><b>Producto anterior: </b>{s.previousProduct}</p>
                                                <p><b>Producto: </b>{s.product}</p>
                                                <p><b>Estado de solicitud: </b>{s.statusSale}</p>
                                                <p><b>Teléfono adicional #1: </b>{s.aditionalPhone}</p>
                                                <p><b>Teléfono adicional #2: </b>{s.aditionalPhone2}</p>
                                                <p><b>Linea a totalizar: </b>{s.totalizeNumber}</p>
                                            </div>
                                            <div>
                                                <p><b>Fecha de portación(PORTA): </b>{moment(s.portationDate).isValid() ? moment(s.portationDate).format('DD/MM/YYYY') : ""}</p>
                                                <p><b>Fecha de activación(PORTA): </b>{moment(s.activationDate).isValid() ? moment(s.activationDate).format('DD/MM/YYYY') : ""}</p>
                                                <p><b>PO: </b>{s.po}</p>
                                                <p><b>Usuario logística: </b>{s.logisticUser}</p>
                                                <p><b>Score: </b>{s.score}</p>
                                                <p><b>Usuario T3: </b>{s.userT3}</p>
                                            </div>
                                        </div>
                                        <div className={estilos.div_observaciones}>
                                            <p><b>Observaciones: </b>{s.observations}</p>
                                            <p><b>Comentarios de logistica: </b>{s.logisticComments}</p>
                                        </div>
                                    </div>
                                )
                            })
                            // )
                        }
                        <p className={textos.p}><b>Ventas: {sales?.length} de {totalSales}</b></p>
                        <div>
                            <Paginated
                                filters={input}
                                totalSales={totalSales}
                            />
                        </div>
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}