import React from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { filterAllSales } from "../actions";
import { provincias, statusSale, products } from "./data";
import { getAllUsers } from "../actions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import estilos from './styles/SearchBar.module.css'
import botones from './styles/Botones.module.css'


export default function SearchBarAdmin({ onFilter }) {

    const dispatch = useDispatch()

    const allUsers = useSelector((state) => state.allUsers)

    const [input, setInput] = useState({
        name: "",
        dni: "",
        saleDateStart: "",
        saleDateEnd: "",
        order: "",
        province: "",
        location: "",
        phone: "",
        aditionalPhone: "",
        aditionalPhone2: "",
        statusSale: "",
        userT3: "",
        approvalDateStart: "",
        approvalDateEnd: "",
        central: "",
        numberApple: "",
        product: "",
        installationDateStart: "",
        installationDateEnd: "",
        seller: "",
        supervisor: ""
    })

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        dispatch(filterAllSales(input))
        onFilter()
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit(e)
        }
    }

    useEffect(() => {
        dispatch(getAllUsers())
    }, [dispatch])


    return (
        <div onKeyDown={handleKeyDown}>
            <input className={estilos.input} type="text" name="name" placeholder="NOMBRE DEL CLIENTE" value={input.name} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="text" name="dni" placeholder="DNI" value={input.dni} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="date" name="saleDateStart" value={input.saleDateStart} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="date" name="saleDateEnd" value={input.saleDateEnd} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="text" name="order" placeholder="NUMERO DE ORDEN" value={input.order} onChange={(e) => handleChange(e)} />
            <select className={estilos.input} name="province" onChange={(e) => handleChange(e)}>
                <option value="" selected>PROVINCIA</option>
                {
                    provincias.map(p => {
                        return (
                            <option value={p.name} key={p.id}>{p.name}</option>
                        )
                    })
                }
            </select>
            <input className={estilos.input} type="text" name="location" placeholder="LOCALIDAD" value={input.location} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="text" name="phone" placeholder="TELEFONO PRINCIPAL" value={input.phone} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="text" name="aditionalPhone" placeholder="TELEFONO ADICIONAL #1" value={input.aditionalPhone} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="text" name="aditionalPhone2" placeholder="TELEFONO ADICIONAL #2" value={input.aditionalPhone2} onChange={(e) => handleChange(e)} />
            <select className={estilos.input} name="statusSale" onChange={(e) => handleChange(e)}>
                <option value="" selected>ESTADO DE SOLICITUD</option>
                {
                    statusSale.map(s => {
                        return (
                            <option value={s.name} key={s.id}>{s.name}</option>
                        )
                    })
                }
            </select>
            <select className={estilos.input} name="userT3" onChange={(e) => handleChange(e)} >
                <option value="" selected>USUARIO T3</option>
                <option value="VSIERRA">VSIERRA</option>
                <option value="FLBARRIO">FLBARRIO</option>
                <option value="4PLAY">4PLAY</option>
            </select>
            <br />
            <input className={estilos.input} type="text" name="central" placeholder="CENTRAL" value={input.central} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="text" name="numberApple" placeholder="MANZANA" value={input.numberApple} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="date" name="approvalDateStart" placeholder="FECHA DE INICIO" value={input.approvalDateStart} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="date" name="approvalDateEnd" placeholder="FECHA DE FIN" value={input.approvalDateEnd} onChange={(e) => handleChange(e)} />
            <select className={estilos.input} name="seller" onChange={(e) => handleChange(e)}>
                <option value="" selected>VENDEDOR</option>
                {
                    allUsers?.map(u => {
                        return (
                            <option value={u.name} key={u.id}>{u.name}</option>
                        )
                    }
                    )}
            </select>
            <select className={estilos.input} name="supervisor" onChange={(e) => handleChange(e)} >
                <option value="" selected>SUPERVISOR</option>
                <option value="FRANCO ZAVALA">FRANCO ZAVALA</option>
                <option value="MARITE PEREZ">MARITE PEREZ</option>
                <option value="LEONARDO ROJAS">LEONARDO ROJAS</option>
                <option value="JULIAN RODRIGUEZ">JULIAN RODRIGUEZ</option>
            </select>
            <br />
            <input className={estilos.input} type="date" name="installationDateStart" value={input.installationDateStart} onChange={(e) => handleChange(e)} />
            <input className={estilos.input} type="date" name="installationDateEnd" value={input.installationDateEnd} onChange={(e) => handleChange(e)} />
            <select className={estilos.input} name="product" onChange={(e) => handleChange(e)} >
            <option value="" selected>PRODUCTO</option>
                {
                    products.map(s => {
                        return (
                            <option value={s.name} key={s.id}>{s.name}</option>
                        )
                    })
                }
            </select>
            <input className={estilos.input} type="text" name="totalizeNumber" placeholder="LINEA A TOTALIZAR" value={input.totalizeNumber} onChange={(e) => handleChange(e)} />
            <br />
            <button className={botones.boton_5} type="Submit" onClick={(e) => handleSubmit(e)}>Filtrar</button>
        </div>
    )
}