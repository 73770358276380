const products = [
    {
        id: 1,
        name: "[FTTH]PLAN FIBRA MOVISTAR 300MB"
    },
    {
        id: 2,
        name: "[FTTH]PLAN FIBRA MOVISTAR 300MB + TV DIGITAL"
    },
    {
        id: 3,
        name: "[FTTH]PLAN FIBRA MOVISTAR 300MB + TV MAX"
    },
    {
        id: 4,
        name: "[FTTH]PLAN FIBRA MOVISTAR 500MB"
    },
    {
        id: 5,
        name: "[FTTH]PLAN FIBRA MOVISTAR 500MB + TV DIGITAL"
    },
    {
        id: 6,
        name: "[FTTH]PLAN FIBRA MOVISTAR 500MB + TV MAX"
    },
    {
        id: 7,
        name: "[FTTH]PLAN FIBRA MOVISTAR 700MB"
    },
    {
        id: 8,
        name: "[FTTH]PLAN FIBRA MOVISTAR 700MB + TV DIGITAL"
    },
    {
        id: 9,
        name: "[FTTH]PLAN FIBRA MOVISTAR 700MB + TV MAX"
    },
    {
        id: 10,
        name: "[FTTH]PLAN FIBRA MOVISTAR 1GB"
    },
    {
        id: 11,
        name: "[FTTH]PLAN FIBRA MOVISTAR 1GB + TV DIGITAL"
    },
    {
        id: 12,
        name: "[FTTH]PLAN FIBRA MOVISTAR 1GB + TV MAX"
    },
    {
        id: 13,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 100MB"
    },
    {
        id: 14,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 100MB + TV DIGITAL"
    },
    {
        id: 15,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 100MB + TV MAX"
    },
    {
        id: 16,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 300MB"
    },
    {
        id: 17,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 300MB + TV DIGITAL"
    },
    {
        id: 18,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 300MB + TV MAX"
    },
    {
        id: 19,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 500MB"
    },
    {
        id: 20,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 500MB + TV DIGITAL"
    },
    {
        id: 21,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 500MB + TV MAX"
    },
    {
        id: 22,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 700MB"
    },
    {
        id: 23,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 700MB + TV DIGITAL"
    },
    {
        id: 24,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 700MB + TV MAX"
    },
    {
        id: 25,
        name: "[FTTH][CLARO] PLAN FIBRA MOVISTAR 1GB"
    },
    {
        id: 26,
        name: "[FTTH][CLARO]PLAN FIBRA MOVISTAR 1GB + TV DIGITAL"
    },
    {
        id: 27,
        name: "[FTTH][CLARO]PLAN FIBRA MOVISTAR 1GB + TV MAX"
    },
    {
        id: 28,
        name: "[PORTA] LINEA NUEVA 3GB - $3.300"
    },
    {
        id: 29,
        name: "[PORTA] LINEA NUEVA 6GB - $4.550"
    },
    {
        id: 30,
        name: "[PORTA] LINEA NUEVA 10GB - $7.350"
    },
    {
        id: 31,
        name: "[PORTA] LINEA NUEVA 15GB - $7.840"
    },
    {
        id: 32,
        name: "[PORTA] LINEA NUEVA 25GB - $8.800"
    },
    {
        id: 33,
        name: "[PORTA] CLARO 1.5GB - $4.400"
    },
    {
        id: 34,
        name: "[PORTA] CLARO 3GB - $5.300"
    },
    {
        id: 35,
        name: "[PORTA] CLARO 6GB - $8.100"
    },
    {
        id: 36,
        name: "[PORTA] CLARO 10GB - $12.200"
    },
    {
        id: 37,
        name: "[PORTA] CLARO 15GB - $13.900"
    },
    {
        id: 38,
        name: "[PORTA] CLARO 25GB - $16.050"
    },
    {
        id: 39,
        name: "[PORTA] PERSONAL 3GB - $7.400"
    },
    {
        id: 40,
        name: "[PORTA] PERSONAL 6GB - $10.450"
    },
    {
        id: 41,
        name: "[PORTA] PERSONAL 10GB - $16.200"
    },
    {
        id: 42,
        name: "[PORTA] PERSONAL 15GB - $17.950"
    },
    {
        id: 43,
        name: "[PORTA] PERSONAL 25GB - $20.500"
    }
]


const statusSale = [
    {
        id: 1,
        name: "FTTH ACTIVADA EN ESPERA DE INSTALACIÓN"
    },
    {
        id: 2,
        name: "FTTH ACTIVADA EN N"
    },
    {
        id: 3,
        name: "FTTH CALIDAD"
    },
    {
        id: 4,
        name: "FTTH CANCELADA"
    },
    {
        id: 5,
        name: "FTTH CON TK A RESOLVER"
    },
    {
        id: 6,
        name: "FTTH ENVIADA 4PLAY"
    },
    {
        id: 7,
        name: "FTTH INSTALADA CON ÉXITO"
    },
    {
        id: 8,
        name: 'FTTH NEGOCIO'
    },
    {
        id: 9,
        name: "FTTH PARA ACTIVAR"
    },
    {
        id: 10,
        name: "FTTH POSTE EN N"
    },
    {
        id: 11,
        name: "FTTH PREVENTA"
    },
    {
        id: 12,
        name: "FTTH RECHAZADA"
    },
    {
        id: 13,
        name: "FTTH RECLAMADA"
    },
    {
        id: 14,
        name: "FTTH SUSPENDIDA"
    },
    {
        id: 15,
        name: "APROBADA"
    },
    {
        id: 16,
        name: "CANCELADA"
    },
    {
        id: 17,
        name: "ENVIADA"
    },
    {
        id: 18,
        name: "PORTA CORREO MOVI"
    },
    {
        id: 19,
        name: "PORTA L PROPIA"
    },
    {
        id: 20,
        name: "PREVENTA"
    },
    {
        id: 21,
        name: "RECHAZADA"
    },
]

const provincias = [
    {
        id: 1,
        name: "BUENOS AIRES"
    },
    {
        id: 2,
        name: "CATAMARCA"
    },
    {
        id: 3,
        name: "CHACO"
    },
    {
        id: 4,
        name: "CHUBUT"
    },
    {
        id: 5,
        name: "CORDOBA"
    },
    {
        id: 6,
        name: "CORRIENTES"
    },
    {
        id: 7,
        name: "ENTRE RIOS"
    },
    {
        id: 8,
        name: "FORMOSA"
    },
    {
        id: 9,
        name: "JUJUY"
    },
    {
        id: 10,
        name: "LA PAMPA"
    },
    {
        id: 11,
        name: "LA RIOJA"
    },
    {
        id: 12,
        name: "MENDOZA"
    },
    {
        id: 13,
        name: "MISIONES"
    },
    {
        id: 14,
        name: "NEUQUEN"
    },
    {
        id: 15,
        name: "RIO NEGRO"
    },
    {
        id: 16,
        name: "SALTA"
    },
    {
        id: 17,
        name: "SAN JUAN"
    },
    {
        id: 18,
        name: "SAN LUIS"
    },
    {
        id: 19,
        name: "SANTA CRUZ"
    },
    {
        id: 20,
        name: "SANTA FE"
    },
    {
        id: 21,
        name: "SANTIAGO DEL ESTERO"
    },
    {
        id: 22,
        name: "TIERRA DEL FUEGO"
    },
    {
        id: 23,
        name: "TUCUMAN"
    }
]

const logistica = [
    // {
    //     id: 1,
    //     name: "SIN USUARIO LOGISTICA POR EL MOMENTO"
    // }
]

const supervisor = [
    {
        id: 1,
        name: "FRANCO ZAVALA"
    },
    {
        id: 2,
        name: "MARITE PEREZ"
    },
    {
        id: 3,
        name: "LEONARDO ROJAS"
    },
    {
        id: 4,
        name: "JULIAN RODRIGUEZ"
    },
    {
        id: 5,
        name: "PAULA SANJORGE"
    },
    {
        id: 6,
        name: "GESTION TECNICO"
    },
    {
        id: 7,
        name: "SIN SUPERVISOR"
    }
]


export { products, statusSale, provincias, logistica, supervisor }