import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { updateUser } from "../actions";
import { NavLink } from "react-router-dom";


export default function UpdateUser() {

    const dispatch = useDispatch()
    const {id} = useParams()
    const allUsers = useSelector((state) => state.allUsers)
    const user = allUsers?.filter(u => u.id === id)

    const [input, setInput] = useState(user)

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        dispatch(updateUser(id, input))
        alert('Usuario modificado')
    }

    return (
        <div>
            <h1>Modificar usuario</h1>
            <form onSubmit={(e) => handleSubmit(e)}>
            <input type="text" name="name" defaultValue={user?.[0].name} onChange={(e) => handleChange(e)} />
            <br />
            <input type="text" name="email" defaultValue={user?.[0].email} onChange={(e) => handleChange(e)} />
            <br />
            <input type="text" name="password" defaultValue={user?.[0].password} onChange={(e) => handleChange(e)} />
            <br />
            <select name="rol" defaultValue={user?.[0].rol} onChange={(e) => handleChange(e)}>
                <option value="" disabled selected>ROL</option>
                <option value="SUPER ADMIN">SUPER ADMIN</option>
                <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                <option value="VENDEDOR">VENDEDOR</option>
                <option value="SUPERVISOR">SUPERVISOR</option>
            </select>
            <br />
            <select name="supervisor" defaultValue={user?.[0].supervisor} onChange={(e) => handleChange(e)}>
                <option value="" disabled selected>SUPERVISOR</option>
                <option value="FRANCO ZAVALA">FRANCO ZAVALA</option>
                <option value="MARITE PEREZ">MARITE PEREZ</option>
                <option value="LEONARDO ROJAS">LEONARDO ROJAS</option>
                <option value="JULIAN RODRIGUEZ">JULIAN RODRIGUEZ</option>
                <option value="PAULA SANJORGE">PAULA SANJORGE</option>
            </select>
            <br />
            <button type="Submit">Editar</button>
            <br />
            </form>
            <NavLink to="/developer/home"><button>Volver</button></NavLink>
        </div>
    )

}