import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyRol } from "../actions";

export default function ChangePassword() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const id = sessionStorage.getItem('userId')

    const [isLoading, setIsLoading] = useState(false)

    const [input, setInput] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    })

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    async function handleSumbit(e) {
        e.preventDefault()
        setIsLoading(true)
        if (input.newPassword !== input.confirmNewPassword) {
            alert('La contraseña nueva no coincide!')
            setIsLoading(false)
            return;
        }
        if(input.newPassword === input.currentPassword) {
            alert('La contraseña nueva no puede ser igual que la anterior')
            setIsLoading(false)
            return;
        }
        try {
            const response = await axios.put(`https://back-crm-app.vercel.app/user/${id}/changepassword`, input)
            alert(response?.data?.msg)
            navigate('/')
        } catch (error) {
            alert(error.response?.data?.msg)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const checkUserRol = async () => {
          try {
            // Realiza la verificación del rol
            await dispatch(verifyRol());
    
            // Obtiene el rol del sessionStorage después de la verificación
            const userRol = sessionStorage.getItem('userRol');
    
            // Realiza la lógica de redirección basada en el rol
            if (userRol === 'INACTIVO') {
              sessionStorage.clear();
              navigate('/');
            }
          } catch (error) {
            console.error('Error during user role verification:', error);
          }
        };
    
        checkUserRol();
      }, [dispatch, navigate]);


    return (
        <div>
            <h2>Cambiar contraseña</h2>
            <form onSubmit={(e) => handleSumbit(e)}>
                <div>
                <label>Contraseña actual: </label>
                <input required type="password" name="currentPassword" value={input.currentPassword} placeholder="CONTRASEÑA ACTUAL" onChange={(e) => handleChange(e)} />
                </div>
                <div>
                    <label>Nueva contraseña: </label>
                    <input required type="password" name="newPassword" value={input.newPassword} placeholder="CONTRASEÑA NUEVA" onChange={(e) => handleChange(e)} />
                </div>
                <div>
                    <label>Confirmar nueva contraseña: </label>
                    <input required type="password" name="confirmNewPassword" value={input.confirmNewPassword} placeholder="CONFIRMAR CONTRASEÑA NUEVA" onChange={(e) => handleChange(e)} />
                </div>
                <button type="Submit" disabled={isLoading}>Cambiar contraseña</button>
            </form>
            <div>
                <NavLink to='/'><button>Volver</button></NavLink>
            </div>
        </div>
    )
}