import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSaleChange, deleteSaleChange,verifyRol } from "../actions";
import { useParams } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import estilos from './styles/SaleChange.module.css'
import botones from './styles/Botones.module.css'

export default function SaleChangeAdmin() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userRol = sessionStorage.getItem('userRol')

    const { saleId } = useParams()

    const saleChanges = useSelector((state) => state.salechange)

    function handleBack() {
        window.history.back()
    }

    useEffect(() => {
        const checkUserRol = async () => {
          try {
            // Realiza la verificación del rol
            await dispatch(verifyRol());
    
            // Obtiene el rol del sessionStorage después de la verificación
            const userRol = sessionStorage.getItem('userRol');
    
            // Realiza la lógica de redirección basada en el rol
            if (userRol === 'INACTIVO') {
              sessionStorage.clear();
              navigate('/');
            }
          } catch (error) {
            console.error('Error during user role verification:', error);
          }
        };
    
        checkUserRol();
      }, [dispatch, navigate]);

    useEffect(() => {
        dispatch(getSaleChange(saleId))
        return function () {
            dispatch(deleteSaleChange())
        }
    }, [(saleId), dispatch])

    return (
        <div>
            {
                userRol === 'ADMINISTRADOR' || userRol === 'SUPERVISOR' || userRol === 'SUPER ADMIN' ?
                    <div>
                        <h1>Registro de cambios</h1>
                        <button className={botones.boton_6} onClick={handleBack}>Volver</button>
                        {
                            saleChanges?.length ?
                                <div>
                                    <div className={estilos.div}>
                                        <div className={estilos.titulo}>
                                            <dd>
                                                <p>Carga de operación</p>
                                            </dd>
                                        </div>
                                        <dd>
                                            <p><b>Cargada por:</b> {saleChanges[0]?.createdBy}</p>
                                            <p><b>Fecha de carga:</b> {saleChanges[0]?.creationDate}</p>
                                        </dd>
                                    </div>
                                    {
                                        saleChanges?.slice(1).map(c => {
                                            const keyMappings = {
                                                clientType: "Tipo de cliente",
                                                productType: "Tipo de producto",
                                                previousProduct: "Producto anterior",
                                                product: "Producto",
                                                saleDate: "Fecha de venta",
                                                name: "Nombre del cliente",
                                                lastName: "Apellido del cliente",
                                                dni: "Dni",
                                                birthday: "Fecha de nacimiento",
                                                email: "Correo",
                                                creditCard: "N° de tarjeta",
                                                expiration: "Fecha de vto",
                                                titular: "Titular",
                                                street: "Calle",
                                                number: "Número",
                                                tower: "Torre/Monoblock",
                                                floor: "Piso",
                                                department: "Departamento",
                                                betweenStreets: "Entre calles",
                                                neighborhood: "Barrio",
                                                apple: "Manzana",
                                                home: "Casa",
                                                references: "Referencias",
                                                province: "Provincia",
                                                location: "Localidad",
                                                postalCode: "Código postal",
                                                phone: "Teléfono principal (fijo)",
                                                aditionalPhone: "Teléfono adicional #1",
                                                aditionalPhone2: "Teléfono adicional #2",
                                                homeCoordinates: "Coordenadas",
                                                decoders: "Decodificadores",
                                                central: "Central",
                                                numberApple: "N° Manzana",
                                                score: "Score",
                                                linkGM: "Link Google Maps",
                                                order: "N° de orden",
                                                status: "Estado de solicitud",
                                                statusSale: 'Estado de solicitud',
                                                userT3: "Usuario T3",
                                                installationDate: 'Fecha de cita',
                                                installationTime: 'Horario de cita',
                                                approvalDate: 'Fecha de Aprobación',
                                                observations: 'Observaciones',
                                                comments: 'Comentarios',
                                                logisticUser: 'Usuario logística',
                                                logisticComments: "Comentarios logística",
                                                phoneCompany: "Compañía de teléfono",
                                                portNumber: "Línea a portar",
                                                pinNumber: "PIN",
                                                pinVto: "Vencimiento de pin",
                                                chipNumber: "Número de chip",
                                                activationDate: "Fecha de activación",
                                                portationDate: "Fecha de portación",
                                                po: "PO",
                                                totalizeNumber: "Línea a totalizar",
                                                seller: "Vendedor"
                                            };
                                            return (
                                                <div className={estilos.div} key={c.id}>
                                                    <div className={estilos.titulo}>
                                                        <dd>
                                                            <p><b>Modificado por:</b> {c.updatedBy} el dia {c.date}</p>
                                                        </dd>
                                                    </div>
                                                    {
                                                        c.oldData && (
                                                            <div>
                                                                {Object.entries(c.oldData).map(([key, value]) => (
                                                                    <div key={key}>
                                                                        <p>
                                                                            <dd>
                                                                                <b>{keyMappings[key]}:</b> {value} {'=>'} {c.newData[key]}
                                                                            </dd>
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <div>
                                    <p>Cargando...</p>
                                </div>
                        }
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}
