import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../actions";
import { useEffect } from "react";
import styles from './styles/developer/Home.module.css'

export default function DeveloperHome() {

    const dispatch = useDispatch()

    const allUsers = useSelector((state) => state.allUsers)

    useEffect(() => {
        dispatch(getAllUsers())
    },[dispatch])

    return (
        <div>
            <br />
            <br />
            <div>
                <NavLink to="/developer/registro"><button>Crear Usuario</button></NavLink>
            </div>
            <table className={styles.table}>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Correo</th>
                    <th>Rol</th>
                    <th>Supervisor</th>
                    <th>Modificar usuario</th>
                    <th>Eliminar usuario</th>
                </tr>
            </thead>
            <tbody>
            {
                allUsers?.map(u => {
                    return (
                        <tr>
                            <td>{u.name}</td>
                            <td>{u.email}</td>
                            <td>{u.rol}</td>
                            <td>{u.supervisor}</td>
                            <td>
                            <NavLink to={'/developer/user/update/' + u.id}><button>Modificar usuario</button></NavLink>
                            </td>
                            <td>
                            <NavLink to={'/developer/user/delete/' + u.id}><button>ELIMINAR</button></NavLink>
                            </td>
                        </tr>
                        )
                    }
                    )
                }
                </tbody>
            </table>
        </div>
    )
}