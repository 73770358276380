import axios from 'axios'


export function createSale(payload) {
    return async function (dispatch) {
        try {
            const userEmail = sessionStorage.getItem('userEmail');

            const verify = await axios.post('https://back-crm-app.vercel.app/verify', { userEmail: userEmail });
            const rol = verify.data;

            sessionStorage.setItem('userRol', rol);

            if (rol !== 'INACTIVO') {
                var info = await axios.post('https://back-crm-app.vercel.app/sales/create', payload)
                return info
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function createUser(payload) {
    return async function (dispatch) {
        var info = await axios.post('https://back-crm-app.vercel.app/user/create', payload)
        return info
    }
}

export function updateUser(id, payload) {
    return async function (dispatch) {
        var info = await axios.put(`https://back-crm-app.vercel.app/user/update/${id}`, payload)
        return info
    }
}

export function deleteUser(id) {
    return async function (dispatch) {
        var info = await axios.delete(`https://back-crm-app.vercel.app/user/delete/${id}`)
        return info
    }
}

export function getAllSales(payload) {
    return async function (dispatch) {
        try {
            const userEmail = sessionStorage.getItem('userEmail');

            const verify = await axios.post('https://back-crm-app.vercel.app/verify', { userEmail: userEmail });
            const rol = verify.data;

            sessionStorage.setItem('userRol', rol);

            const info = await axios.get('https://back-crm-app.vercel.app/sales/all', {
                params: payload,
            });
            dispatch({
                type: 'GET_SALES',
                payload: info.data.sales
            });
            dispatch({
                type: 'GET_TOTAL_SALES',
                payload: info.data.totalSales
            })
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
}

export function deleteAllSales() {
    return {
        type: 'DELETE_ALL_SALES'
    }
}

export function getAllUsers() {
    return async function (dispatch) {
        try {
            const info = await axios.get('https://back-crm-app.vercel.app/user/all')
            return dispatch({
                type: 'GET_ALL_USERS',
                payload: info.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getUserSale(userId) {
    return async function (dispatch) {
        var info = await axios.get(`https://back-crm-app.vercel.app/user/sales/${userId}`)
        return dispatch({
            type: 'USER_SALES',
            payload: info.data
        })
    }
}


export function saleDetail(id) {
    return async function (dispatch) {
        try {
            var info = await axios.get(`https://back-crm-app.vercel.app/sales/detail/${id}`)
            return dispatch({
                type: 'GET_DETAIL',
                payload: info
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export function deleteDetail() {
    return {
        type: 'DELETE_DETAIL'
    }
}

export function editSale(id, payload) {
    return async function (dispatch) {
        var info = await axios.put(`https://back-crm-app.vercel.app/sales/update/${id}`, payload)
        return info
    }
}

export function getSaleChange(saleId) {
    return async function (dispatch) {
        var info = await axios.get(`https://back-crm-app.vercel.app/changelog/${saleId}`)
        return dispatch({
            type: 'GET_SALECHANGE',
            payload: info.data
        })
    }
}

export function deleteSaleChange() {
    return {
        type: 'DELETE_SALECHANGE'
    }
}

export function filterAllSales(payload) {
    return async function (dispatch) {
        try {
            const userEmail = sessionStorage.getItem('userEmail');

            const verify = await axios.post('https://back-crm-app.vercel.app/verify', { userEmail: userEmail });
            const rol = verify.data;

            sessionStorage.setItem('userRol', rol);

            var info = await axios.get('https://back-crm-app.vercel.app/filter', { params: payload })
            return dispatch({
                type: "FILTER_ALL_SALES",
                payload: info.data
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export function verifyRol() {
    return async function (dispatch) {
        try {
            const userEmail = sessionStorage.getItem('userEmail');

            const verify = await axios.post('https://back-crm-app.vercel.app/verify', { userEmail: userEmail });
            const rol = verify.data;

            sessionStorage.setItem('userRol', rol);
        } catch (error) {
            console.log(error)
        }
    }
}

export function deleteFilterAllSales() {
    return {
        type: 'DELETE_FILTER_ALL_SALES'
    }
}

export const saveFilters = (filters) => {
    return {
        type: 'SAVE_FILTERS',
        payload: filters
    }
}