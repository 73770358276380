import React, {useEffect, useState} from "react";
import {useDispatch} from 'react-redux'
import {getAllSales} from '../actions'
import botones from './styles/Botones.module.css'

export default function Paginated({ filters, totalSales }) {

    const [currentPage, setCurrentPage] = useState(parseInt(sessionStorage.getItem('currentPage')) || 1);
    const dispatch = useDispatch()

    const totalPages = Math.ceil(totalSales / 30)

    function previousPage(e) {
        e.preventDefault()
        if(currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage)
            dispatch(getAllSales({page: newPage, ...filters}))
            sessionStorage.setItem('currentPage', newPage)
        }
    }

    function nextPage(e) {
        e.preventDefault()
        const newPage = currentPage + 1;
        setCurrentPage(newPage);
        dispatch(getAllSales({page: newPage, ...filters}));
        sessionStorage.setItem('currentPage', newPage)
    }

    useEffect(() => {
        setCurrentPage(parseInt(sessionStorage.getItem('currentPage')))
    })

    return (
        <nav>
            <ul>
                <button className={botones.boton_4} onClick={(e) => previousPage(e)} disabled={currentPage === 1}>Anterior</button>
                <button className={botones.boton_8}>{currentPage}</button>
                <button className={botones.boton_4} onClick={(e) => nextPage(e)} disabled={currentPage === totalPages || totalSales === 0}>Siguiente</button>
            </ul>
        </nav>
    )
}