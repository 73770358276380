import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import { updateUser } from "../../actions";
import {supervisor} from '../data'

export default function SuperAdminUpdateUser() {

    const dispatch = useDispatch()
    const {id} = useParams()

    const userRol = sessionStorage.getItem('userRol')

    const allUsers = useSelector((state) => state.allUsers)
    const user = allUsers?.filter(u => u.id === id)

    const [input, setInput] = useState(user)

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        dispatch(updateUser(id, input))
        alert('Usuario modificado!')
    }

    return (
        <div>
            {
                userRol === 'SUPER ADMIN' ?
                    <div>
                        <h1>Modificar usuario</h1>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <input type="text" name="name" defaultValue={user?.[0].name}  onChange={(e) => handleChange(e)}/>
                            <br />
                            <input type="email" name="email" defaultValue={user?.[0].email} onChange={(e) => handleChange(e)} />
                            <br />
                            <input type="password" name="password" defaultValue={user?.[0].password} onChange={(e) => handleChange(e)} />
                            <br />
                            <select name="rol" defaultValue={user?.[0].rol} onChange={(e) => handleChange(e)}>
                                <option value="" disabled selected>ROL</option>
                                <option value="SUPER ADMIN">SUPER ADMIN</option>
                                <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                                <option value="SUPERVISOR">SUPERVISOR</option>
                                <option value="VENDEDOR">VENDEDOR</option>
                                <option value="INACTIVO">INACTIVO</option>
                            </select>
                            <br />
                            <select name="supervisor" defaultValue={user?.[0].supervisor} onChange={(e) => handleChange(e)}>
                                <option value="" disabled selected>SUPERVISOR</option>
                                {
                                    supervisor.map(s => {
                                        return (
                                            <option value={s.name} key={s.id}>{s.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <br />
                            <button type="Submit">Editar</button>
                            <br />
                        </form>
                        <NavLink to='/super admin/usuarios/todos'><button>Volver</button></NavLink>
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}
