import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllSales } from "../actions";
import { NavLink } from "react-router-dom";
import SearchBarAdmin from "./Admin_SearchBar";
import Paginated from "./Paginated";
import moment from "moment";
import editIcon from './assets/icono editar.png'
import registerIcon from './assets/icono registro.png'
import estilos from './styles/Table_Sales.module.css'
import botones from './styles/Botones.module.css'
import textos from './styles/Textos.module.css'


export default function Sales4Play() {
    const dispatch = useDispatch()
    const userRol = sessionStorage.getItem('userRol')

    const allSales = useSelector((state) => state.sales)
    const sales = allSales.filter(s => s.userT3 === '4PLAY')

    const [currentPage, setCurrentPage] = useState(parseInt(sessionStorage.getItem('currentPage')) || 1)
    const [salesPerPage] = useState(30)

    const indexOfLastSale = currentPage * salesPerPage;
    const indexOfFirstSale = indexOfLastSale - salesPerPage;
    const currentSales = sales?.slice(indexOfFirstSale, indexOfLastSale);

    const paginated = (pageNumber) => {
        setCurrentPage(pageNumber)
        sessionStorage.setItem('currentPage', pageNumber.toString());
    }

    const cleanFilters = (e) => {
        e.preventDefault()
        dispatch(getAllSales())
        setCurrentPage(1)
    }

    function handleBack() {
        window.history.back()
    }

    const handleFilter = () => {
        setCurrentPage(1)
    }

    return (
        <div>
            {
                userRol === '4PLAY' ?
                    <div>
                        <h1>Solicitudes</h1>
                        <SearchBarAdmin onFilter={handleFilter} />
                        <div className={botones.div_botones}>
                            <button className={botones.boton_7} onClick={handleBack}>Volver</button>
                            <button className={botones.boton_7} onClick={(e) => cleanFilters(e)}>Actualizar</button>
                        </div>
                        {
                            currentSales && currentSales.map(s => {
                                return (
                                    <div key={s.id} className={estilos.div_general} style={s.statusSale === "FTTH POSTE EN N" ? { border: "2px solid #00aa9e" } : s.statusSale === "FTTH CON TK A RESOLVER" ? { border: "1px solid #004e79" } : s.statusSale === "FTTH PARA ACTIVAR" ? { border: "1px solid #c005ff" } : s.statusSale === "FTTH PREVENTA" || s.statusSale === "PREVENTA" ? { border: "1px solid #337ab7" } : s.statusSale === "FTTH RECLAMADA" ? { border: "1px solid #797979" } : s.statusSale === "FTTH RECHAZADA" || s.statusSale === "RECHAZADA" ? { border: "1px solid #dc1e00" } : s.statusSale === "FTTH INSTALADA CON ÉXITO" || s.statusSale === "APROBADA" ? { border: "1px solid #0d7900" } : s.statusSale === "FTTH ACTIVADA EN ESPERA DE INSTALACIÓN" ? { border: "1px solid #c89700" } : s.statusSale === "FTTH CANCELADA" || s.statusSale === "CANCELADA" ? { border: "1px solid black" } : s.statusSale === "FTTH SUSPENDIDA" ? { border: "1px solid #FA721E " } : s.statusSale === "FTTH CALIDAD" ? { border: "1px solid salmon" } : { border: "1px solid white" }}>
                                        <div className={estilos.div_detalles} style={s.statusSale === "FTTH POSTE EN N" ? { backgroundColor: "#00aa9e", color: "white" } : s.statusSale === "FTTH CON TK A RESOLVER" ? { backgroundColor: "#004e79", color: "white" } : s.statusSale === "FTTH PARA ACTIVAR" ? { backgroundColor: "#c005ff", color: "white" } : s.statusSale === "FTTH PREVENTA" || s.statusSale === 'PREVENTA' ? { backgroundColor: "#337ab7", color: "white" } : s.statusSale === "FTTH RECLAMADA" ? { backgroundColor: "#797979", color: "white" } : s.statusSale === "FTTH RECHAZADA" || s.statusSale === "RECHAZADA" ? { backgroundColor: "#dc1e00", color: "white" } : s.statusSale === "FTTH INSTALADA CON ÉXITO" || s.statusSale === "APROBADA" ? { backgroundColor: "#0d7900", color: "white" } : s.statusSale === "FTTH ACTIVADA EN ESPERA DE INSTALACIÓN" ? { backgroundColor: "#c89700", color: "white" } : s.statusSale === "FTTH CANCELADA" || s.statusSale === "CANCELADA" ? { backgroundColor: "black", color: "white" } : s.statusSale === "FTTH SUSPENDIDA" ? { backgroundColor: "#FA721E ", color: "white" } : s.statusSale === "FTTH CALIDAD" ? { backgroundColor: "salmon", color: "white" } : { backgroundColor: "white" }}>
                                            <p><b>Solicitud: </b>{s.order}</p>
                                            <p><b>Fecha cita: </b>{moment(s.installationDate).isValid() ? moment(s.installationDate).format('DD/MM/YYYY') : ""} {s.installationTime}</p>
                                            <p><b>Fecha de aprobación: </b>{moment(s.approvalDate).isValid() ? moment(s.approvalDate).format('DD/MM/YYYY') : ""}</p>
                                            <div>
                                                <NavLink to={'/4play/ventas/detalles/' + s.id}><img src={editIcon} alt="EDITAR" width="25px" height="25px" style={{ margin: "5px" }} /></NavLink>
                                                <NavLink to={'/admin/registro/' + s.id}><img src={registerIcon} alt="REGISTRO" width="25px" height="25px" style={{ margin: "5px" }} /></NavLink>
                                            </div>
                                        </div>
                                        <div className={estilos.div_comentarios} style={s.statusSale === "FTTH POSTE EN N" ? { backgroundColor: "#00aa9e", color: "white" } : s.statusSale === "FTTH CON TK A RESOLVER" ? { backgroundColor: "#004e79", color: "white" } : s.statusSale === "FTTH PARA ACTIVAR" ? { backgroundColor: "#c005ff", color: "white" } : s.statusSale === "FTTH PREVENTA" || s.statusSale === 'PREVENTA' ? { backgroundColor: "#337ab7", color: "white" } : s.statusSale === "FTTH RECLAMADA" ? { backgroundColor: "#797979", color: "white" } : s.statusSale === "FTTH RECHAZADA" || s.statusSale === "RECHAZADA" ? { backgroundColor: "#dc1e00", color: "white" } : s.statusSale === "FTTH INSTALADA CON ÉXITO" || s.statusSale === "APROBADA" ? { backgroundColor: "#0d7900", color: "white" } : s.statusSale === "FTTH ACTIVADA EN ESPERA DE INSTALACIÓN" ? { backgroundColor: "#c89700", color: "white" } : s.statusSale === "FTTH CANCELADA" || s.statusSale === "CANCELADA" ? { backgroundColor: "black", color: "white" } : s.statusSale === "FTTH SUSPENDIDA" ? { backgroundColor: "#FA721E ", color: "white" } : s.statusSale === "FTTH CALIDAD" ? { backgroundColor: "salmon", color: "white" } : { backgroundColor: "white" }}>
                                            <p className={estilos.p_comentarios}><b>Comentarios: </b>{s.comments}</p>
                                        </div>
                                        <div className={estilos.div_datos}>
                                            <div>
                                                <p><b>Cliente: </b>{s.name} {s.lastName}</p>
                                                <p><b>Dni: </b>{s.dni}</p>
                                                <p><b>Linea a portar(PORTA): </b>{s.portNumber}</p>
                                                <p><b>Compañia de teléfono(PORTA): </b>{s.phoneCompany}</p>
                                                <p><b>PIN(PORTA): </b>{s.pinNumber}</p>
                                                <p><b>Fecha de vto de PIN(PORTA): </b>{moment(s.pinVto).isValid() ? moment(s.pinVto).format('DD/MM/YYYY') : ""}</p>
                                            </div>
                                            <div>
                                                <p><b>Fecha de venta: </b>{moment(s.saleDate).format('DD/MM/YYYY')}</p>
                                                <p><b>Vendedor: </b>{s.users[0]?.name}</p>
                                                <p><b>Provincia: </b>{s.province}</p>
                                                <p><b>Localidad: </b>{s.location}</p>
                                                <p><b>Teléfono adicional #1: </b>{s.aditionalPhone}</p>
                                                <p><b>Teléfono adicional #2: </b>{s.aditionalPhone2}</p>
                                            </div>
                                            <div>
                                                <p><b>Producto anterior: </b>{s.previousProduct}</p>
                                                <p><b>Producto: </b>{s.product}</p>
                                                <p><b>Estado de solicitud: </b>{s.statusSale}</p>
                                                <p><b>Score: </b>{s.score}</p>
                                                <p><b>Linea a totalizar: </b>{s.totalizeNumber}</p>
                                            </div>
                                            <div>
                                                <p><b>Fecha de portación(PORTA): </b>{moment(s.portationDate).isValid() ? moment(s.portationDate).format('DD/MM/YYYY') : ""}</p>
                                                <p><b>Fecha de activación(PORTA): </b>{moment(s.activationDate).isValid() ? moment(s.activationDate).format('DD/MM/YYYY') : ""}</p>
                                                <p><b>PO: </b>{s.po}</p>
                                                <p><b>Usuario logística: </b>{s.logisticUser}</p>
                                                <p><b>Usuario T3: </b>{s.userT3}</p>
                                            </div>
                                        </div>
                                        <div className={estilos.div_observaciones}>
                                            <p><b>Observaciones: </b>{s.observations}</p>
                                            <p><b>Comentarios de logistica: </b>{s.logisticComments}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <p className={textos.p}><b>Ventas: {currentSales?.length} de {sales?.length}</b></p>
                        <div>
                            <Paginated
                                salesPerPage={salesPerPage}
                                totalSales={sales?.length}
                                currentPage={currentPage}
                                paginated={paginated}
                            />
                        </div>
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}