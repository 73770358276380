import React, {useEffect} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import botones from './styles/Botones.module.css'
import { useDispatch } from "react-redux";
import { getAllSales, deleteFilterAllSales, verifyRol } from "../actions";
import preciosMovi from './assets/Precios-Movi.png'
import PreciosCompe from './assets/Precios-compe.png'

export default function AdminHome() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = sessionStorage.getItem('userName')
    const userRol = sessionStorage.getItem('userRol')


    function handleLogout() {
        sessionStorage.clear()
        navigate('/')
    }

    useEffect(() => {
        const checkUserRol = async () => {
          try {
            // Realiza la verificación del rol
            await dispatch(verifyRol());
    
            // Obtiene el rol del sessionStorage después de la verificación
            const userRol = sessionStorage.getItem('userRol');
    
            // Realiza la lógica de redirección basada en el rol
            if (userRol === 'INACTIVO') {
              sessionStorage.clear();
              navigate('/');
            }
          } catch (error) {
            console.error('Error during user role verification:', error);
          }
        };
    
        checkUserRol();
      }, [dispatch, navigate]);

    useEffect(() => {
        sessionStorage.setItem('currentPage', "1")
        dispatch(getAllSales({page: 1}))
        dispatch(deleteFilterAllSales())
    }, [dispatch])

    return (
        <div>
            {
                userRol === 'ADMINISTRADOR' ?
                    <div>
                        <h1>Bienvenido {user}(Administrativo)</h1>
                        <NavLink to='/admin/cargar'><button className={botones.boton_1}>Nueva Solicitud</button></NavLink>
                        <br />
                        <NavLink to='/admin/ventas/todas'><button className={botones.boton_1}>Solicitudes</button></NavLink>
                        <br />
                        <NavLink to='/user/change password'><button className={botones.boton_1}>Cambiar contraseña</button></NavLink>
                        <br />
                        <button className={botones.boton_1} onClick={handleLogout}>Cerrar Sesión</button>
                        <br />
                        <img src={preciosMovi} alt="precios movistar.png" />
                        <br />
                        <img src={PreciosCompe} alt="precios competencia.png" />
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}