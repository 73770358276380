import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { saleDetail, deleteDetail, editSale, verifyRol } from '../actions/index'
import { useParams } from 'react-router-dom'
import { NavLink, useNavigate } from "react-router-dom";
import estilos from './styles/SaleForm.module.css'
import botones from './styles/Botones.module.css'


export default function EditSaleAdmin(props) {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const userName = sessionStorage.getItem('userName')
    const userRol = sessionStorage.getItem('userRol')


    const detail = useSelector((state) => state.detail)

    const { id } = useParams()

    const [input, setInput] = useState(detail)

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        const hasAnyChanges = Object.keys(input).some((fieldName) => {
            const originalValue = detail?.data[fieldName];
            return input[fieldName] !== originalValue
        })
        if(!hasAnyChanges) {
            alert('No se han realizado cambios en la venta')
            return;
        }
        const updateData = {
            ...input,
            updatedBy: userName
        }
        dispatch(editSale(id, updateData))
        alert('Venta modificada!')
        navigate('/vendedor/ventas/todas')
    }

    function handleBack() {
        window.history.back()
    }

    useEffect(() => {
        const checkUserRol = async () => {
          try {
            // Realiza la verificación del rol
            await dispatch(verifyRol());
    
            // Obtiene el rol del sessionStorage después de la verificación
            const userRol = sessionStorage.getItem('userRol');
    
            // Realiza la lógica de redirección basada en el rol
            if (userRol === 'INACTIVO') {
              sessionStorage.clear();
              navigate('/');
            }
          } catch (error) {
            console.error('Error during user role verification:', error);
          }
        };
    
        checkUserRol();
      }, [dispatch, navigate]);

    useEffect(() => {
        dispatch(saleDetail(id))
        return function () {
            dispatch(deleteDetail())
        }
    }, [(id), dispatch])

    return (
        <div>
            {
                userRol === 'VENDEDOR' ?
                    <div>
                        <button className={botones.boton_6} onClick={handleBack}>Volver</button>
                        <br />
                        <br />
                        <div className={estilos.div_general}>
                            <div className={estilos.div_general_titulo}>
                                <h2 className={estilos.general_titulo}>Observaciones</h2>
                            </div>
                            {
                                detail?.data ?
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <textarea className={estilos.div_general_textarea} name="observations" cols="30" rows="10" placeholder="OBSERVACIONES" defaultValue={detail.data.observations} onChange={(e) => handleChange(e)} />
                                        <br />
                                        <br />
                                        <button className={botones.boton_3} type="Submit">Modificar</button>
                                        <br />
                                        <br />
                                    </form>
                                    :
                                    <div>
                                        <p>Cargando...</p>
                                    </div>
                            }
                        </div>
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}
