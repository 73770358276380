import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { createSale, getAllUsers, getAllSales, verifyRol } from '../actions'
import { products, statusSale, provincias, logistica } from "./data";
import { useNavigate } from "react-router-dom";
import estilos from './styles/SaleForm.module.css'
import textos from './styles/Textos.module.css'

export default function SalesForm() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const allUsers = useSelector((state) => state.allUsers)

    const userName = sessionStorage.getItem('userName')

    const [input, setInput] = useState({
        clientType: "",
        productType: "",
        previousProduct: "",
        product: "",
        saleDate: new Date().toISOString().substring(0, 10),
        name: "",
        lastName: "",
        dni: "",
        birthday: "",
        email: "",
        creditCard: "",
        expiration: "",
        titular: "",
        street: "",
        number: "",
        tower: "",
        floor: "",
        department: "",
        betweenStreets: "",
        neighborhood: "",
        apple: "",
        home: "",
        references: "",
        province: "",
        location: "",
        postalCode: "",
        phone: "",
        aditionalPhone: "",
        aditionalPhone2: "",
        homeCoordinates: "",
        decoders: "",
        central: "",
        numberApple: "",
        score: "",
        linkGM: "",
        order: "",
        statusSale: "",
        userT3: "",
        installationDate: "",
        installationTime: "",
        approvalDate: "",
        observations: "",
        comments: "",
        logisticUser: "",
        logisticComments: "",
        phoneCompany: "",
        portNumber: "",
        pinNumber: "",
        pinVto: "",
        chipNumber: "",
        activationDate: "",
        portationDate: "",
        po: "",
        totalizeNumber: "",
        seller: "",
        maker: ""
    })

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value.toUpperCase()
        })
    }

    function handleSelect(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        try {
            const data = {
                ...input,
                maker: userName
            }
            dispatch(createSale(data))
            .then(() => {
                dispatch(getAllSales({page: sessionStorage.getItem('currentPage')}))
            })
            alert('Venta cargada correctamente!')
            navigate('/admin/ventas/todas')
        } catch (e) {
            console.log(e)
            alert(e)
        }
    }

    function handleBack() {
        window.history.back()
    }

    useEffect(() => {
        const checkUserRol = async () => {
          try {
            // Realiza la verificación del rol
            await dispatch(verifyRol());
    
            // Obtiene el rol del sessionStorage después de la verificación
            const userRol = sessionStorage.getItem('userRol');
    
            // Realiza la lógica de redirección basada en el rol
            if (userRol === 'INACTIVO') {
              sessionStorage.clear();
              navigate('/');
            }
          } catch (error) {
            console.error('Error during user role verification:', error);
          }
        };
    
        checkUserRol();
      }, [dispatch, navigate]);

    useEffect(() => {
        dispatch(getAllUsers())
    }, [dispatch])

    return (
        <div className={estilos.div_contenedor}>
            <h1>Nueva Solicitud</h1>
            <button className={estilos.boton_back} onClick={handleBack}>Volver</button>
            <br />
            <br />
            <form action="POST" onSubmit={(e) => handleSubmit(e)}>
                <div className={estilos.div_general}>
                    <div className={estilos.div_general_titulo}>
                        <h2 className={estilos.general_titulo}>General</h2>
                    </div>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_3}><b>Tipo de cliente</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <select className={estilos.div_general_select} required name="clientType" onChange={(e) => handleSelect(e)}>
                        <option value="" disabled selected>TIPO DE CLIENTE</option>
                        <option value="INDIVIDUOS">INDIVIDUOS</option>
                        <option value="NEGOCIOS">NEGOCIOS</option>
                    </select>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_35}><b>Tipo de producto</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <select className={estilos.div_general_select} required name="productType" onChange={(e) => handleSelect(e)}>
                        <option value="" disabled selected>TIPO DE PRODUCTO</option>
                        <option value="FIBRA">FIBRA</option>
                        <option value="PORTABILIDAD">PORTABILIDAD</option>
                    </select>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_4}><b>Producto anterior</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <select className={estilos.div_general_select} required name="previousProduct" onChange={(e) => handleSelect(e)}>
                        <option value="" disabled selected>PRODUCTO ANTERIOR</option>
                        <option value="ALTA MIXTA FIBRA">ALTA MIXTA FIBRA</option>
                        <option value="ALTA PURA FIBRA">ALTA PURA FIBRA</option>
                        <option value="ALTA MIGRA FIBRA">ALTA MIGRA FIBRA</option>
                        <option value="LINEA NUEVA">LINEA NUEVA</option>
                        <option value="PORTA">PORTA</option>
                    </select>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_34}><b>Producto</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <select className={estilos.div_general_select} required name="product" onChange={(e) => handleSelect(e)}>
                        <option value="" disabled selected>PRODUCTO</option>
                        {
                            products.map(p => {
                                return (
                                    <option value={p.name} key={p.id}>{p.name}</option>
                                )
                            })
                        }
                    </select>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_35}><b>Decodificadores</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <select className={estilos.div_general_select} name="decoders" defaultValue="DECODIFICADORES" onChange={(e) => handleSelect(e)}>
                        <option value="DECODIFICADORES">DECODIFICADORES</option>
                        <option value="SIN DECOS">SIN DECOS</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div className={estilos.div_general}>
                    <div className={estilos.div_general_titulo}>
                        <h2 className={estilos.general_titulo}>Datos de venta</h2>
                    </div>
                    {/* COMENTARIO SEPARADOR */}
                    <div>
                        <label className={textos.text_1}><b>Fecha de venta</b></label>
                        <label className={textos.text_2}><b>Vendedor</b></label>
                    </div>
                    {/* COMENTARIO SEPARADOR */}
                    <input className={estilos.div_general_input} required type="date" name="saleDate" value={input.saleDate} disabled />
                    <select className={estilos.div_general_input} required name="seller" onChange={(e) => handleSelect(e)}>
                        <option value="" disabled selected>VENDEDOR</option>
                        {
                            allUsers?.map(u => {
                                return (
                                    <option value={u.name} key={u.id}>{u.name}</option>
                                )
                            })
                        }
                    </select>
                    <div>
                        <label className={textos.text_3}><b>Usuario logística</b></label>
                    </div>
                    <select className={estilos.div_general_select} name="logisticUser" onChange={(e) => handleSelect(e)}>
                        <option value="" selected>USUARIO LOGISTICA(PORTABILIDAD)</option>
                        {
                            logistica?.map(e => {
                                return (
                                    <option value={e.name} key={e.id}>{e.name}</option>
                                )
                            })
                        }
                    </select>
                    <div>
                        <label className={textos.text_4}><b>Comentarios logística</b></label>
                    </div>
                    <textarea className={estilos.div_general_textarea} name="logisticComments" cols="30" rows="10" placeholder="COMENTARIOS DE LOGISTICA" value={input.logisticComments} onChange={(e) => handleChange(e)} />
                </div>
                <div className={estilos.div_general}>
                    <div className={estilos.div_general_titulo}>
                        <h2 className={estilos.general_titulo}>Datos del cliente</h2>
                    </div>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_5}><b>Nombre</b></label>
                        <label className={textos.text_6}><b>Apellido</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} required type="text" name="name" placeholder="NOMBRE" value={input.name} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} required type="text" name="lastName" placeholder="APELLIDO" value={input.lastName} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_7}><b>Dni</b></label>
                        <label className={textos.text_8}><b>Fecha de nacimiento</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} required type="text" name="dni" placeholder="DNI" value={input.dni} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} required type="date" name="birthday" placeholder="FECHA DE NACIMIENTO" value={input.birthday} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_9}><b>Email</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} required type="text" name="email" placeholder="CORREO" value={input.email} onChange={(e) => handleSelect(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_10}><b>Tarjeta</b></label>
                        <label className={textos.text_11}><b>Vencimiento</b></label>
                        <label><b>Titular</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} required type="text" maxLength="16" name="creditCard" placeholder="N° DE TARJETA" value={input.creditCard} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} required type="text" name="expiration" placeholder="FECHA DE VTO" value={input.expiration} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} required type="text" name="titular" placeholder="TITULAR" value={input.titular} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_12}><b>Calle</b></label>
                        <label className={textos.text_13}><b>Número</b></label>
                        <label><b>Torre/Monoblock</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} required type="text" name="street" placeholder="CALLE" value={input.street} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} required type="text" name="number" placeholder="NÚMERO" value={input.number} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="tower" placeholder="TORRE/MONOBLOCK" value={input.tower} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_14}><b>Piso</b></label>
                        <label className={textos.text_15}><b>Departamento</b></label>
                        <label><b>Entre calles</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="text" name="floor" placeholder="PISO" value={input.floor} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="department" placeholder="DEPARTAMENTO" value={input.department} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} required type="text" name="betweenStreets" placeholder="ENTRE CALLES" value={input.betweenStreets} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_16}><b>Barrio</b></label>
                        <label className={textos.text_17}><b>Manzana</b></label>
                        <label><b>Casa</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="text" name="neighborhood" placeholder="BARRIO" value={input.neighborhood} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="apple" placeholder="MANZANA" value={input.apple} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="home" placeholder="CASA" value={input.home} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_34}><b>Referencias</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_select} type="text" name="references" placeholder="REFERENCIAS" value={input.references} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_19}><b>Provincia</b></label>
                        <label className={textos.text_20}><b>Localidad</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <select className={estilos.div_general_input} required name="province" onChange={(e) => handleSelect(e)}>
                        <option value="" disabled selected>PROVINCIA</option>
                        {
                            provincias.map(p => {
                                return (
                                    <option value={p.name} key={p.id}>{p.name}</option>
                                )
                            })
                        }
                    </select>
                    <input className={estilos.div_general_input} required type="text" name="location" placeholder="LOCALIDAD" value={input.location} onChange={(e) => handleChange(e)} />
                    <br />
                    <div>
                        <label className={textos.text_21}><b>Código postal</b></label>
                        <label className={textos.text_22}><b>Teléfono principal(fijo)</b></label>
                    </div>
                    <input className={estilos.div_general_input} required type="text" maxLength="4" name="postalCode" placeholder="CÓDIGO POSTAL" value={input.postalCode} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="phone" placeholder="TELÉFONO PRINCIPAL (FIJO)" value={input.phone} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_23}><b>Teléfono adicional #1</b></label>
                        <label className={textos.text_24}><b>Teléfono adicional #2</b></label>
                        <label><b>Línea a totalizar</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} required type="text" name="aditionalPhone" placeholder="TELÉFONO ADICIONAL #1" value={input.aditionalPhone} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} required type="text" name="aditionalPhone2" placeholder="TELÉFONO ADICIONAL #2" value={input.aditionalPhone2} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="totalizeNumber" placeholder="LINEA A TOTALIZAR" value={input.totalizeNumber} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_25}><b>Observaciones</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <textarea className={estilos.div_general_textarea} name="observations" cols="30" rows="10" placeholder="OBSERVACIONES" value={input.observations} onChange={(e) => handleChange(e)} />
                </div>
                <div className={estilos.div_general}>
                    <div className={estilos.div_general_titulo}>
                        <h2 className={estilos.general_titulo}>Datos de fibra</h2>
                    </div>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_26}><b>Central</b></label>
                        <label className={textos.text_27}><b>N° Manzana</b></label>
                        <label><b>Coordenadas</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="text" maxLength="4" name="central" placeholder="CENTRAL" value={input.central} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" maxLength="4" name="numberApple" placeholder="N° MANZANA" value={input.numberApple} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="homeCoordinates" placeholder="COORDENADAS DEL DOMICILIO" value={input.homeCoordinates} onChange={(e) => handleChange(e)} />
                </div>
                <div className={estilos.div_general}>
                    <div className={estilos.div_general_titulo}>
                        <h2 className={estilos.general_titulo}>Datos de portabilidad</h2>
                    </div>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_28}><b>Compañía de teléfono</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <select className={estilos.div_general_select} name="phoneCompany" defaultValue="COMPAÑIA DE TELEFONO" onChange={(e) => handleSelect(e)}>
                        <option value="COMPAÑIA DE TELEFONO">COMPAÑIA DE TELEFONO</option>
                        <option value="CLARO">CLARO</option>
                        <option value="MOVISTAR">MOVISTAR</option>
                        <option value="PERSONAL">PERSONAL</option>
                        <option value="NEXTEL">NEXTEL</option>
                        <option value="DESCONOCIDO">DESCONOCIDO</option>
                    </select>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_29}><b>Línea a portar</b></label>
                        <label className={textos.text_30}><b>Pin</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="text" name="portNumber" placeholder="LINEA A PORTAR" value={input.portNumber} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="pinNumber" placeholder="PIN" value={input.pinNumber} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_31}><b>Vencimiento de pin</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="date" name="pinVto" value={input.pinVto} onChange={(e) => handleChange(e)} />
                </div>
                <div className={estilos.div_general}>
                    <div className={estilos.div_general_titulo}>
                        <h2 className={estilos.general_titulo}>Activación</h2>
                    </div>
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_32}><b>Score</b></label>
                        <label className={textos.text_33}><b>Link Google Maps</b></label>
                        <label><b>Estado de solicitud</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="text" name="score" placeholder="SCORE" value={input.score} onChange={(e) => handleChange(e)} />
                    <input className={estilos.div_general_input} type="text" name="linkGM" placeholder="LINK GOOGLE MAPS" value={input.linkGM} onChange={(e) => handleSelect(e)} />
                    <select className={estilos.div_general_input} required name="statusSale" onChange={(e) => handleSelect(e)}>
                        <option value="" disabled selected>ESTADO DE SOLICITUD</option>
                        {
                            statusSale.map(s => {
                                return (
                                    <option value={s.name} key={s.id}>{s.name}</option>
                                )
                            })
                        }
                    </select>
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_40}><b>N° de orden</b></label>
                        <label><b>Usuario T3</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="text" name="order" placeholder="N° DE ORDEN" value={input.order} onChange={(e) => handleChange(e)} />
                    <select className={estilos.div_general_input} name="userT3" value={input.userT3} onChange={(e) => handleSelect(e)}>
                        <option value="" selected>USUARIO T3</option>
                        <option value="VSIERRA">VSIERRA</option>
                        <option value="FLBARRIO">FLBARRIO</option>
                        <option value="4PLAY">4PLAY</option>
                    </select>
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_41}><b>Fecha de cita</b></label>
                        <label><b>Hora de cita</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="date" name="installationDate" value={input.installationDate} onChange={(e) => handleChange(e)} />
                    <select className={estilos.div_general_input} name="installationTime" value={input.installationTime} onChange={(e) => handleSelect(e)}>
                        <option value="" selected>HORA DE CITA</option>
                        <option value="8HS a 12HS">8HS a 12HS</option>
                        <option value="12HS a 17HS">12HS a 17HS</option>
                    </select>
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_7}><b>Fecha de aprobación</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <input className={estilos.div_general_input} type="date" name="approvalDate" value={input.approvalDate} onChange={(e) => handleChange(e)} />
                    <br />
                    {/* SEPARADOR */}
                    <div>
                        <label className={textos.text_34}><b>Comentarios</b></label>
                    </div>
                    {/* SEPARADOR */}
                    <textarea className={estilos.div_general_textarea} name="comments" cols="30" rows="10" placeholder="COMENTARIOS" value={input.comments} onChange={(e) => handleChange(e)} />
                </div>
                <button className={estilos.botones} type="Submit">Cargar</button>
                <br />
                <br />
            </form>
        </div>
    )
}
