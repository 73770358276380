import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createUser } from "../actions";
import { NavLink } from "react-router-dom";

export default function CreateUser() {
    
    const dispatch = useDispatch()
    const [input, setInput] = useState({
        name: "",
        email: "",
        password: "",
        rol: "",
        supervisor: ""
    })

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSelect(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        dispatch(createUser(input))
        alert('Usuario creado')
    }


    return (
        <div>
            <h1>Formulario de creación de usuario</h1>
            <form action="POST" onSubmit={(e) => handleSubmit(e)}>
                <input type="text" name="name" placeholder="NOMBRE" value={input.name} onChange={(e) => handleChange(e)} />
                <br />
                <br />
                <input type="email" name="email" placeholder="CORREO" value={input.email} onChange={(e) => handleChange(e)} />
                <br />
                <br />
                <input type="password" name="password" placeholder="CONTRASEÑA" value={input.password} onChange={(e) => handleChange(e)} />
                <br />
                <br />
                <select name="rol" onChange={(e) => handleSelect(e)}>
                    <option value="" disabled selected>ROL</option>
                    <option value="SUPER ADMIN">SUPER ADMIN</option>
                    <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                    <option value="VENDEDOR">VENDEDOR</option>
                    <option value="SUPERVISOR">SUPERVISOR</option>
                    <option value="4PLAY">4PLAY</option>
                </select>
                <br />
                <br />
                <select name="supervisor" onChange={(e) => handleSelect(e)}>
                    <option value="" disabled selected>SUPERVISOR</option>
                    <option value="FRANCO ZAVALA">FRANCO ZAVALA</option>
                    <option value="MARITE PEREZ">MARITE PEREZ</option>
                    <option value="LEONARDO ROJAS">LEONARDO ROJAS</option>
                    <option value="JULIAN RODRIGUEZ">JULIAN RODRIGUEZ</option>
                    <option value="PAULA SANJORGE">PAULA SANJORGE</option>
                </select>
                <br />
                <br />
                <button type="Submit">Registrarse</button>
            </form>
            <br />
            <NavLink to="/developer/home"><button>Volver</button></NavLink>
        </div>
    )
}
