import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// RUTAS SUPER ADMIN
import SuperAdminHome from './components/Super Admin/Home';
import SuperAdminUsers from './components/Super Admin/Users';
import SuperAdminCreateUser from './components/Super Admin/CreateUser';
import SuperAdminUpdateUser from './components/Super Admin/UpdateUser';

// RUTAS ADMIN
import AdminHome from './components/Admin_Home';
import AdminSales from './components/Admin_Sales';
import AdminSaleForm from './components/Admin_SaleForm'
import EditSaleAdmin from './components/Admin_EditSale';
import SaleChangeAdmin from './components/Admin_SaleChange';

//RUTAS VENDEDOR
import VendedorHome from './components/Vendedor_Home';
import VendedorSalesForm from './components/Vendedor_SalesForm';
import VendedorSales from './components/Vendedor_Sales';
import EditSaleVendedor from './components/Vendedor_EditSale';

// RUTAS DESARROLLADOR
import DeveloperHome from './components/Developer_Home';
import DeleteUser from './components/Developer_DeleteUser';
import UpdateUser from './components/Developer_updateUser';

// RUTAS SUPERVISOR
import SupervisorHome from './components/Supervisor_Home'
import SupervisorSaleForm from './components/Supervisor_SaleForm';
import SupervisorSales from './components/Supervisor_Sales';
import EditSaleSupervisor from './components/Supervisor_EditSale';

// RUTAS 4PLAY
import Home4Play from './components/4Play_Home';
import Sales4Play from './components/4Play_Sales';
import EditSale4Play from './components/4Play_EditSale';

import CreateUser from './components/Developer_CreateUser';
import Login from './components/Login';
import ChangePassword from './components/ChangePassword';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Login/>} />
          <Route path='user/change password' element={<ChangePassword/>} />

          <Route path='/super admin/home' element={<SuperAdminHome/>} />
          <Route path='/super admin/usuarios/todos' element={<SuperAdminUsers/>} />
          <Route path='/super admin/usuario/registro' element={<SuperAdminCreateUser />} />
          <Route path='/super admin/usuario/editar/:id' element= {<SuperAdminUpdateUser/>} />

          <Route path='/developer/registro' element={<CreateUser />} />
          <Route path='/developer/home' element={<DeveloperHome/>} />
          <Route path='/developer/user/delete/:id' element={<DeleteUser/>} />
          <Route path='/developer/user/update/:id' element={<UpdateUser/>} />
          
          <Route exact path='/admin/home' element={ <AdminHome />} />
          <Route path='/admin/cargar' element={<AdminSaleForm/>} />
          <Route path='/admin/ventas/todas' element={<AdminSales />} />
          <Route path='/admin/ventas/detalles/:id' element={<EditSaleAdmin />} />
          <Route path='/admin/registro/:saleId' element={<SaleChangeAdmin />} />

          <Route exact path='/vendedor/home' element={ <VendedorHome />} />
          <Route path='/venta/cargar' element={<VendedorSalesForm />} />
          <Route path='/vendedor/ventas/todas' element={< VendedorSales />} />
          <Route path='/vendedor/ventas/detalles/:id' element={<EditSaleVendedor />} />

          <Route path='/supervisor/home' element={<SupervisorHome/>} />
          <Route path='/supervisor/cargar' element={<SupervisorSaleForm />} />
          <Route path='/supervisor/ventas/todas' element={<SupervisorSales/>} />
          <Route path='/supervisor/ventas/detalles/:id' element={<EditSaleSupervisor />} />

          <Route path='/4play/home' element={<Home4Play />} />
          <Route path='/4play/ventas/todas' element= {<Sales4Play />} />
          <Route path='/4play/ventas/detalles/:id' element= {<EditSale4Play />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
