import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { deleteUser } from "../actions";
import { NavLink } from "react-router-dom";

export default function DeleteUser() {

    const dispatch = useDispatch()
    const {id} = useParams()

    useEffect(() => {
        dispatch(deleteUser(id))
    },[(id),dispatch])

    return (
        <div>
            <p>Usuario eliminado</p>
            <NavLink to="/developer/home"><button>Volver</button></NavLink>
        </div>
    )
}