import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { saleDetail, deleteDetail, editSale, verifyRol } from "../actions";
import { products, statusSale, provincias, logistica } from "./data";
import estilos from './styles/SaleForm.module.css'
import botones from './styles/Botones.module.css'
import textos from './styles/Textos.module.css'


export default function EditSaleSupervisor() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userName = sessionStorage.getItem('userName')
    const userRol = sessionStorage.getItem('userRol')

    const allUsers = useSelector((state) => state.allUsers)
    const users = allUsers?.filter((u) => u.supervisor === userName)

    const detail = useSelector((state) => state.detail)

    const { id } = useParams()

    const [input, setInput] = useState(detail)

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSelect(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        const hasAnyChanges = Object.keys(input).some((fieldName) => {
            const originalValue = detail?.data[fieldName];
            return input[fieldName] !== originalValue
        })
        if(!hasAnyChanges) {
            alert('No se han realizado cambios en la venta')
            return;
        }
        const updateData = {
            ...input,
            updatedBy: userName
        }
        dispatch(editSale(id, updateData))
        alert('venta modificada!')
        navigate('/supervisor/ventas/todas')
    }

    function handleBack() {
        window.history.back()
    }

    useEffect(() => {
        const checkUserRol = async () => {
          try {
            // Realiza la verificación del rol
            await dispatch(verifyRol());
    
            // Obtiene el rol del sessionStorage después de la verificación
            const userRol = sessionStorage.getItem('userRol');
    
            // Realiza la lógica de redirección basada en el rol
            if (userRol === 'INACTIVO') {
              sessionStorage.clear();
              navigate('/');
            }
          } catch (error) {
            console.error('Error during user role verification:', error);
          }
        };
    
        checkUserRol();
      }, [dispatch, navigate]);

    useEffect(() => {
        dispatch(saleDetail(id))
        return function () {
            dispatch(deleteDetail())
        }
    }, [(id), dispatch])

    return (
        <div>
            <button className={botones.boton_6} onClick={handleBack}>Volver</button>
            {
                userRol === 'SUPERVISOR' ?
                    <div>
                        {
                            detail?.data ?
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <br />
                                    <div className={estilos.div_general}>
                                        <div className={estilos.div_general_titulo}>
                                            <h2 className={estilos.general_titulo}>General</h2>
                                        </div>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_3}><b>Tipo de cliente</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <select className={estilos.div_general_select} name="clientType" defaultValue={detail.data.clientType} onChange={(e) => handleChange(e)}>
                                            <option value="" disabled selected>TIPO DE CLIENTE</option>
                                            <option value="INDIVIDUOS">INDIVIDUOS</option>
                                            <option value="NEGOCIOS">NEGOCIOS</option>
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_35}><b>Tipo de producto</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <select className={estilos.div_general_select} name="productType" defaultValue={detail.data.productType} onChange={(e) => handleSelect(e)}>
                                            <option value="" disabled selected>TIPO DE PRODUCTO</option>
                                            <option value="FIBRA">FIBRA</option>
                                            <option value="PORTABILIDAD">PORTABILIDAD</option>
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_4}><b>Producto anterior</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <select className={estilos.div_general_select} name="previousProduct" defaultValue={detail.data.previousProduct} onChange={(e) => handleSelect(e)}>
                                            <option value="" disabled selected>PRODUCTO ANTERIOR</option>
                                            <option value="ALTA MIXTA FIBRA">ALTA MIXTA FIBRA</option>
                                            <option value="ALTA PURA FIBRA">ALTA PURA FIBRA</option>
                                            <option value="ALTA MIGRA FIBRA">ALTA MIGRA FIBRA</option>
                                            <option value="LINEA NUEVA">LINEA NUEVA</option>
                                            <option value="PORTA">PORTA</option>
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_34}><b>Producto</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <select className={estilos.div_general_select} name="product" defaultValue={detail.data.product} onChange={(e) => handleSelect(e)}>
                                            <option value="" disabled selected>PRODUCTO</option>
                                            {
                                                products.map(p => {
                                                    return (
                                                        <option value={p.name} id={p.id}>{p.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_35}><b>Decodificadores</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <select className={estilos.div_general_select} name="decoders" defaultValue={detail.data.decoders} onChange={(e) => handleSelect(e)}>
                                            <option value="" disabled selected>DECODIFICADORES</option>
                                            <option value="SIN DECOS">SIN DECOS</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                    <div className={estilos.div_general}>
                                        <div className={estilos.div_general_titulo}>
                                            <h2 className={estilos.general_titulo}>Datos de venta</h2>
                                        </div>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_1}><b>Fecha de venta</b></label>
                                            <label className={textos.text_2}><b>Vendedor</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="date" name="saleDate" placeholder="FECHA DE VENTA" value={detail.data.saleDate} disabled />
                                        <select className={estilos.div_general_input} name="seller" defaultValue={detail.data.users[0]?.name} onChange={(e) => handleSelect(e)}>
                                            <option value="" disabled selected>VENDEDOR</option>
                                            {
                                                users?.map(u => {
                                                    return (
                                                        <option value={u.name} id={u.id}>{u.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_3}><b>Usuario logística</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <select className={estilos.div_general_select} name="logisticUser" defaultValue={detail.data.logisticUser} onChange={(e) => handleSelect(e)} >
                                            <option value="" disabled selected>USUARIO LOGISTICA(PORTABILIDAD)</option>
                                            {
                                                logistica?.map(e => {
                                                    return (
                                                        <option value={e.name} key={e.id}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_42}><b>Comentarios de logística</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <textarea className={estilos.div_general_textarea} name="logisticComments" cols="30" rows="10" placeholder="COMENTARIOS DE LOGISTICA" defaultValue={detail.data.logisticComments} onChange={(e) => handleChange(e)} />
                                    </div>
                                    <div className={estilos.div_general}>
                                        <div className={estilos.div_general_titulo}>
                                            <h2 className={estilos.general_titulo}>Datos del cliente</h2>
                                        </div>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_5}><b>Nombre</b></label>
                                            <label className={textos.text_6}><b>Apellido</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="name" placeholder="NOMBRE" defaultValue={detail.data.name} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="lastName" placeholder="APELLIDO" defaultValue={detail.data.lastName} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_7}><b>Dni</b></label>
                                            <label className={textos.text_8}><b>Fecha de nacimiento</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="dni" placeholder="DNI" defaultValue={detail.data.dni} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="date" name="birthday" placeholder="FECHA DE NACIMIENTO" defaultValue={detail.data.birthday} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_9}><b>Email</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="email" placeholder="CORREO" defaultValue={detail.data.email} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_10}><b>Tarjeta</b></label>
                                            <label className={textos.text_11}><b>Vencimiento</b></label>
                                            <label><b>Titular</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" maxLength="16" name="creditCard" placeholder="N° DE TARJETA" defaultValue={detail.data.creditCard} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="expiration" placeholder="FECHA DE VTO" defaultValue={detail.data.expiration} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="titular" placeholder="TITULAR" defaultValue={detail.data.titular} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_12}><b>Calle</b></label>
                                            <label className={textos.text_13}><b>Número</b></label>
                                            <label><b>Torre/Monoblock</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="street" placeholder="CALLE" defaultValue={detail.data.street} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="number" placeholder="NÚMERO" defaultValue={detail.data.number} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="tower" placeholder="TORRE/MONOBLOCK" defaultValue={detail.data.tower} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_14}><b>Piso</b></label>
                                            <label className={textos.text_15}><b>Departamento</b></label>
                                            <label><b>Entre calles</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="floor" placeholder="PISO" defaultValue={detail.data.floor} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="department" placeholder="DEPARTAMENTO" defaultValue={detail.data.department} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="betweenStreets" placeholder="ENTRE CALLES" defaultValue={detail.data.betweenStreets} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_16}><b>Barrio</b></label>
                                            <label className={textos.text_17}><b>Manzana</b></label>
                                            <label><b>Casa</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="neighborhood" placeholder="BARRIO" BARRIO defaultValue={detail.data.neighborhood} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="apple" placeholder="MANZANA" defaultValue={detail.data.apple} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="home" placeholder="CASA" defaultValue={detail.data.home} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_34}><b>Referencias</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_select} type="text" name="references" placeholder="REFERENCIAS" defaultValue={detail.data.references} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_19}><b>Provincia</b></label>
                                            <label className={textos.text_20}><b>Localidad</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <select className={estilos.div_general_input} name="province" defaultValue={detail.data.province} onChange={(e) => handleSelect(e)}>
                                            <option value="" disabled selected>PROVINCIA</option>
                                            {
                                                provincias.map(p => {
                                                    return (
                                                        <option value={p.name} id={p.id}>{p.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <input className={estilos.div_general_input} type="text" name="location" placeholder="LOCALIDAD" defaultValue={detail.data.location} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_21}><b>Código postal</b></label>
                                            <label className={textos.text_22}><b>Teléfono principal(fijo)</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" maxLength="4" name="postalCode" placeholder="CÓDIGO POSTAL" defaultValue={detail.data.postalCode} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="phone" placeholder="TELÉFONO PRINCIPAL (FIJO)" defaultValue={detail.data.phone} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_23}><b>Teléfono adicional #1</b></label>
                                            <label className={textos.text_24}><b>Teléfono adicional #2</b></label>
                                            <label><b>Línea a totalizar</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="aditionalPhone" placeholder="TELÉFONO ADICIONAL (CELULAR)" defaultValue={detail.data.aditionalPhone} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="aditionalPhone2" placeholder="TELÉFONO ADICIONAL #2 (CELULAR)" defaultValue={detail.data.aditionalPhone2} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="totalizeNumber" placeholder="LINEA A TOTALIZAR" defaultValue={detail.data.totalizeNumber} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_25}><b>Observaciones</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <textarea className={estilos.div_general_textarea} name="observations" cols="30" rows="10" placeholder="OBSERVACIONES" defaultValue={detail.data.observations} onChange={(e) => handleChange(e)} />
                                    </div>
                                    <div className={estilos.div_general}>
                                        <div className={estilos.div_general_titulo}>
                                            <h2 className={estilos.general_titulo}>Datos de fibra</h2>
                                        </div>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_26}><b>Central</b></label>
                                            <label className={textos.text_27}><b>N° Manzana</b></label>
                                            <label><b>Coordenadas</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="central" placeholder="CENTRAL" defaultValue={detail.data.central} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="numberApple" placeholder="N° MANZANA" defaultValue={detail.data.numberApple} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="homeCoordinates" placeholder="COORDENADAS DEL DOMICILIO" defaultValue={detail.data.homeCoordinates} onChange={(e) => handleChange(e)} />
                                    </div>
                                    <div className={estilos.div_general}>
                                        <div className={estilos.div_general_titulo}>
                                            <h2 className={estilos.general_titulo}>Datos de portabilidad</h2>
                                        </div>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_28}><b>Compañía de teléfono</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <select className={estilos.div_general_select} name="phoneCompany" defaultValue={detail.data.phoneCompany} onChange={(e) => handleSelect(e)} >
                                            <option value="" disabled selected>COMPAÑIA DE TELEFONO</option>
                                            <option value="CLARO">CLARO</option>
                                            <option value="MOVISTAR">MOVISTAR</option>
                                            <option value="PERSONAL">PERSONAL</option>
                                            <option value="NEXTEL">NEXTEL</option>
                                            <option value="DESCONOCIDO">DESCONOCIDO</option>
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_29}><b>Línea a portar</b></label>
                                            <label className={textos.text_30}><b>Pin</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="portNumber" placeholder="LINEA A PORTAR" defaultValue={detail.data.portNumber} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="pinNumber" placeholder="PIN" defaultValue={detail.data.pinNumber} onChange={(e) => handleChange(e)} />
                                        <br />
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_21}><b>Fecha de vencimiento</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="date" name="pinVto" defaultValue={detail.data.pinVto} onChange={(e) => handleChange(e)} />
                                    </div>
                                    <div className={estilos.div_general}>
                                        <div className={estilos.div_general_titulo}>
                                            <h2 className={estilos.general_titulo}>Activación</h2>
                                        </div>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_32}><b>Score</b></label>
                                            <label className={textos.text_33}><b>Link Google Maps</b></label>
                                            <label><b>Estado de solicitud</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="score" placeholder="SCORE" defaultValue={detail.data.score} onChange={(e) => handleChange(e)} />
                                        <input className={estilos.div_general_input} type="text" name="linkGM" placeholder="LINK GOOGLE MAPS" defaultValue={detail.data.linkGM} onChange={(e) => handleChange(e)} />
                                        <select className={estilos.div_general_input} name="statusSale" defaultValue={detail.data.statusSale} onChange={(e) => handleSelect(e)}>
                                            <option value="" disabled selected>ESTADO DE SOLICITUD</option>
                                            {
                                                statusSale.map(s => {
                                                    return (
                                                        <option value={s.name} id={s.id}>{s.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_40}><b>N° de orden</b></label>
                                            <label><b>Usuario T3</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <input className={estilos.div_general_input} type="text" name="order" placeholder="N° DE ORDEN" defaultValue={detail.data.order} disabled />
                                        <select className={estilos.div_general_input} name="userT3" defaultValue={detail.data.userT3} onChange={(e) => handleSelect(e)}>
                                            <option value="" selected>USUARIO T3</option>
                                            <option value="VSIERRA">VSIERRA</option>
                                            <option value="FLBARRIO">FLBARRIO</option>
                                            <option value="4PLAY">4PLAY</option>
                                        </select>
                                        {/* SEPARADOR */}
                                        <div>
                                            <label className={textos.text_34}><b>Comentarios</b></label>
                                        </div>
                                        {/* SEPARADOR */}
                                        <textarea className={estilos.div_general_textarea} name="comments" cols="30" rows="10" placeholder="COMENTARIOS" defaultValue={detail.data.comments} onChange={(e) => handleChange(e)} />
                                    </div>
                                    <button className={botones.boton_5} type="Submit">Modificar</button>
                                </form>
                                :
                                <div>
                                    <p>Cargando...</p>
                                </div>
                        }
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )

}