import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import botones from './styles/Botones.module.css'
import { useDispatch } from "react-redux";
import { getAllSales } from "../actions";

export default function Home4Play() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = sessionStorage.getItem('userName')
    const userRol = sessionStorage.getItem('userRol')

    function handleLogout() {
        sessionStorage.clear()
        navigate('/')
    }

    useEffect(() => {
        dispatch(getAllSales())
        sessionStorage.setItem('currentPage', '1')
    }, [dispatch])

    return (
        <div>
            {
                userRol === '4PLAY' ?
                    <div>
                        <h1>Bienvenido {user}</h1>
                        <NavLink to='/4play/ventas/todas'><button className={botones.boton_1}>Solicitudes</button></NavLink>
                        <br />
                        <button className={botones.boton_1} onClick={handleLogout}>Cerrar Sesión</button>
                    </div> :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}