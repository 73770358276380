import React, {useEffect} from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, deleteUser } from "../../actions";
import styles from '../styles/developer/Home.module.css';

export default function SuperAdminUsers() {
    const dispatch = useDispatch();
    const userRol = sessionStorage.getItem('userRol');
    const allUsers = useSelector((state) => state.allUsers);

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    const handleDeleteUser = (userId) => {
        const shouldDelete = window.confirm("¿Estás seguro de que deseas eliminar este usuario?");
        if (shouldDelete) {
            dispatch(deleteUser(userId))
                .then(() => {
                    // Después de eliminar el usuario, vuelva a cargar la lista de usuarios.
                    dispatch(getAllUsers());
                })
                .catch((error) => {
                    // Manejar errores en caso de que ocurra un problema durante la eliminación.
                    console.error("Error al eliminar usuario:", error);
                });
        }
    };

    return (
        <div>
            {
                userRol === 'SUPER ADMIN' ?
                    <div>
                        <div>
                            <NavLink to='/super admin/usuario/registro'><button>Registrar Usuario</button></NavLink>
                        </div>
                        <p><b>Usuarios totales: {allUsers?.length}</b></p>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Correo</th>
                                    <th>Rol</th>
                                    <th>Supervisor</th>
                                    <th>Modificar Usuario</th>
                                    <th>Eliminar Usuario</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allUsers?.map(u => {
                                        return (
                                            <tr key={u.id}>
                                                <td>{u.name}</td>
                                                <td>{u.email}</td>
                                                <td>{u.rol}</td>
                                                <td>{u.supervisor}</td>
                                                <td><NavLink to={'/super admin/usuario/editar/' + u.id}><button>MODIFICAR</button></NavLink></td>
                                                <td>
                                                    <button onClick={() => {
                                                        handleDeleteUser(u.id);
                                                    }}>ELIMINAR</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}