import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createUser } from "../../actions";
import { NavLink } from "react-router-dom";
import { supervisor } from "../data";

export default function SuperAdminCreateUser() {

    const dispatch = useDispatch()

    const userRol = sessionStorage.getItem('userRol')

    const [input, setInput] = useState({
        name: "",
        email: "",
        password: "",
        rol: "",
        supervisor: ""
    })

    function handleChange(e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        dispatch(createUser(input))
        alert('Usuario creado')
    }

    return (
        <div>
            {
                userRol === 'SUPER ADMIN' ?
                    <div>
                        <h1>Registro</h1>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <input type="text" name="name" placeholder="NOMBRE" value={input.name} onChange={(e) => handleChange(e)} />
                            <br />
                            <input type="email" name="email" placeholder="EMAIL" value={input.email} onChange={(e) => handleChange(e)} />
                            <br />
                            <input type="password" name="password" placeholder="CONTRASEÑA" value={input.password} onChange={(e) => handleChange(e)} />
                            <br />
                            <select name="rol" onChange={(e) => handleChange(e)}>
                                <option value="" disabled selected>ROL</option>
                                <option value="SUPER ADMIN">SUPER ADMIN</option>
                                <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                                <option value="SUPERVISOR">SUPERVISOR</option>
                                <option value="VENDEDOR">VENDEDOR</option>
                                <option value="4PLAY">4PLAY</option>
                            </select>
                            <br />
                            <select name="supervisor" onChange={(e) => handleChange(e)}>
                                <option value="" disabled selected>SUPERVISOR</option>
                                {
                                    supervisor.map(s => {
                                        return (
                                            <option value={s.name} key={s.id}>{s.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <br />
                            <button type="Submit">Registrar</button>
                        </form>
                        <br />
                        <NavLink to='/super admin/usuarios/todos'><button>Volver</button></NavLink>
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}