import React, {useEffect} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllSales, deleteFilterAllSales } from "../../actions";
import botones from '../styles/Botones.module.css'
import preciosMovi from '../assets/Precios-Movi.png'
import preciosCompe from '../assets/Precios-compe.png'

export default function SuperAdminHome() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userName = sessionStorage.getItem('userName')
    const userRol = sessionStorage.getItem('userRol')

    function handleLogout() {
        sessionStorage.clear()
        navigate('/')
    }

    useEffect(() => {
        sessionStorage.setItem('currentPage', 1)
        dispatch(getAllSales({page: 1}))
        dispatch(deleteFilterAllSales())
    }, [dispatch])

    return (
        <div>
            {
                userRol === 'SUPER ADMIN' ? 
                    <div>
                        <h1>Bienvenido {userName} - SUPER ADMIN</h1>
                        <NavLink to='/admin/cargar'><button className={botones.boton_1}>Nueva Solicitud</button></NavLink>
                        <br />
                        <NavLink to='/admin/ventas/todas'><button className={botones.boton_1}>Solicitudes</button></NavLink>
                        <br />
                        <NavLink to='/super admin/usuarios/todos'><button className={botones.boton_1}>Ver Usuarios</button></NavLink>
                        <br />
                        <button className={botones.boton_1} onClick={handleLogout}>Cerrar Sesión</button>
                        <br />
                        <img src={preciosMovi} alt="precios movistar.png" />
                        <br />
                        <img src={preciosCompe} alt="precios competencia.png" />
                    </div>
                    :
                    <div>
                        <h1>ACCESO DENEGADO!</h1>
                        <NavLink to='/'><button>Volver</button></NavLink>
                    </div>
            }
        </div>
    )
}
